import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { HclConfigService } from '../../config/hcl.config.service'
import { HttpShowError } from '../../decorator/http/http-show-error.decorator'
import { TagDto } from '../../interfaces/dto/tag-dto'
import { InvocationResult } from '../../interfaces/invocation-result'
import { Tag, TargetType } from '../../interfaces/tag'
import { QueryScope } from '../../interfaces/query-scope'

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) { }

  createTag(tag: Tag): Observable<Tag> {
    const url = `${this.configService.mediaApiBaseUrl}/tags`
    const tagDto: TagDto = {
      name: tag.name,
      targetTypes: tag.targetTypes,
      system: tag.system
    }
    return this.httpClient.post<InvocationResult>(url, tagDto).pipe(
      map((res: InvocationResult) => res.data)
    )
  }

  @HttpShowError(false)
  getTag(id: string): Observable<Tag> {
    const url = `${this.configService.mediaApiBaseUrl}/tags/${id}`
    return this.httpClient.get<InvocationResult>(url).pipe(
      map((res: InvocationResult) => res.data)
    )
  }

  @HttpShowError(true)
  updateTag(tag: Tag): Observable<Tag> {
    const url = `${this.configService.mediaApiBaseUrl}/tags/${tag.id}`
    const tagDto: TagDto = {
      id: tag.id,
      name: tag.name,
      targetTypes: tag.targetTypes,
      system: tag.system
    }
    return this.httpClient.put<InvocationResult>(url, tagDto).pipe(
      map((res: InvocationResult) => res.data)
    )
  }

  // TODO: ?
  // default perPage on MS was 500
  getAvailableTags(targetTypes?: TargetType[]): Observable<Tag[]> {
    return this.getTagsWithPaging(0, 500, undefined, targetTypes, false, undefined).pipe(
      map((res: InvocationResult) => res.data)
    )
  }

  getTagsWithPaging(
    page: number,
    perPage: number,
    queryScope?: QueryScope,
    targetTypes?: TargetType[],
    system?: boolean,
    search?: string
  ): Observable<InvocationResult> {
    const url = `${this.configService.mediaApiBaseUrl}/tags`
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    if (queryScope) {
      queryParams = queryParams.append('queryScope', queryScope)
    }
    if (targetTypes) {
      targetTypes.forEach(targetType => {
        queryParams = queryParams.append('targetTypes', targetType)
      })
    }
    if (system != null) {
      queryParams = queryParams.append('system', system)
    }
    if (search) {
      queryParams = queryParams.append('search', search)
    }
    return this.httpClient.get<InvocationResult>(url, { params: queryParams }).pipe(
      map((res: InvocationResult) => res)
    )
  }

  getTagsByIds(tagIds: string[]): Observable<Tag[]> {
    const url = `${this.configService.mediaApiBaseUrl}/tags/tagIds`
    let queryParams = new HttpParams()
    tagIds.forEach(tagId => {
      queryParams = queryParams.append('tagIds', tagId)
    })
    return this.httpClient.get<InvocationResult>(url, { params: queryParams }).pipe(
      map((res: InvocationResult) => res.data)
    )
  }
}
