import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from "rxjs";
import * as shiro from "shiro-trie";
import { ScopeService } from '../../services/scope/scope.service';
import { CollectionUtil } from '../../util/collection.util';

export enum CmnIfPermOperator {
  AND,
  OR
}

@Directive({
    selector: '[cmnIfPerm]',
    standalone: true
})
export class CmnIfPermDirective implements OnInit, OnDestroy {

  subscription: Subscription = new Subscription()

  private hasView = false
  private requiredPermissions: Set<string> = new Set<string>()
  private operator: CmnIfPermOperator = CmnIfPermOperator.OR
  private cid?: string

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopeService: ScopeService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscription.add(
      this.scopeService.getScopeObservable().subscribe(_ => {
        this.updateVisiblity()
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  @Input()
  set cmnIfPerm(permissions: string[]) {
    let newPermissionsSet = new Set<string>()
    if (permissions && permissions.length > 0) {
      for (let permission of permissions) {
        newPermissionsSet.add(permission)
      }
    }

    if (CollectionUtil.setsEqual(newPermissionsSet, this.requiredPermissions)) {
      return
    }
    this.requiredPermissions = newPermissionsSet
    this.updateVisiblity()
  }

  @Input("cmnIfPermOp")
  set cmnIfPermOp(newOperatorStr: string) {
    let newOperator = newOperatorStr.toLowerCase() === 'and' ? CmnIfPermOperator.AND : CmnIfPermOperator.OR;
    if (newOperator === this.operator) {
      return
    }

    this.operator = newOperator
    this.updateVisiblity()
  }

  @Input("cmnIfPermCid")
  set cmnIfPermCid(cid: string) {
    if (cid === this.cid) {
      return
    }
    this.cid = cid
    this.updateVisiblity()
  }

  private updateVisiblity() {
    let operator = this.operator
    this.subscription.add(
      this.scopeService.getPermissions(this.cid).subscribe(permissions => {
        let showElt = false
        let trie = shiro.newTrie()
        trie.add(...permissions)
        if (this.requiredPermissions.size > 0) {
          if (operator === CmnIfPermOperator.OR) {
            showElt = Array.from(this.requiredPermissions).some(requiredPermission => trie.check(requiredPermission))
          } else {
            showElt = Array.from(this.requiredPermissions).every(requiredPermission => trie.check(requiredPermission))
          }
        }

        if (showElt && !this.hasView) {
          this.viewContainer.createEmbeddedView(this.templateRef)
          this.hasView = true
        } else if (!showElt && this.hasView) {
          this.viewContainer.clear()
          this.hasView = false
        }
      })
    )
  }
}
