import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ReactiveFormsModule, Validators } from "@angular/forms";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { Directory } from "../../../../interfaces/directory";
import { Media } from "../../../../interfaces/media";
import { DirectoryService } from "../../../../services/directory/directory.service";
import { MediaService } from "../../../../services/media/media.service";
import { NgIf } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'app-directory-dialog',
    templateUrl: './directory-dialog.component.html',
    styleUrls: ['./directory-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatButtonModule, MatTooltipModule, TranslateModule]
})
export class DirectoryDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  title!: string
  directory!: Directory
  directoryForm!: UntypedFormGroup
  directoryMedias: Media[] = []

  constructor(
    public dialogRef: MatDialogRef<DirectoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private directoryService: DirectoryService,
    private mediaService: MediaService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.title = this.data.title
    this.directory = this.data.directory
    this.directoryForm = this.formBuilder.group({
      name: [this.directory.name, Validators.required]
    })
    this.retrieveDirectoryMedias()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveDirectoryMedias(): void {
    this.subscriptions.add(
      this.mediaService.getMediasWithPaging(
        0,
        1,
        undefined,
        undefined,
        undefined,
        true,
        this.directory.id
      ).subscribe(invocationResult => {
        this.directoryMedias = invocationResult.data
      })
    )
  }

  doSubmit() {
    this.directory.name = this.directoryForm.value.name
    if (this.directory.id) {
      this.subscriptions.add(
        this.directoryService.updateDirectory(this.directory.id, this.directory).subscribe(updatedDirectory => {
          this.dialogRef.close(updatedDirectory)
        })
      )
    } else {
      this.subscriptions.add(
        this.directoryService.createDirectory(this.directory).subscribe(createdDirectory => {
          this.dialogRef.close(createdDirectory)
        })
      )
    }
  }

  doDelete() {
    this.subscriptions.add(
      this.directoryService.deleteDirectory(this.directory.id as string).subscribe(_ => {
        this.dialogRef.close(false)
      })
    )
  }
}
