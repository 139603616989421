<h2 class="delete-dialog__title" mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content class="delete-dialog__content" [innerHTML]="data.content"></mat-dialog-content>
<mat-dialog-actions class="delete-dialog__actions">
  <button mat-raised-button mat-dialog-close>
    {{ "CANCEL" | translate}}
  </button>
  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    {{ "DELETE" | translate}}
  </button>
</mat-dialog-actions>
