import {AfterContentInit, Component, forwardRef, Input, OnInit} from '@angular/core';
import { NgxMatTimepickerFieldComponent, NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { MatError, MatFormField, MatHint, MatLabel, MatSuffix } from "@angular/material/form-field";
import { ValidatorErrorsComponent } from "hcl-lib";
import {
  ControlValueAccessor, FormBuilder,
  FormGroup, NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle, MatDateRangeInput, MatDateRangePicker, MatEndDate, MatStartDate } from "@angular/material/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { MatInput } from "@angular/material/input";
import {MatIcon} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {map} from "rxjs/operators";
import {Subscription} from "rxjs";

type FormDateAndHourType = {date: Date | null, time: string | null} | null

@Component({
  selector: 'app-datetime',
  standalone: true,
  imports: [
    NgxMatTimepickerFieldComponent,
    MatError,
    ValidatorErrorsComponent,
    ReactiveFormsModule,
    MatFormField,
    NgxMatTimepickerModule,
    MatDatepickerToggle,
    MatSuffix,
    MatDateRangePicker,
    MatDateRangeInput,
    MatEndDate,
    MatStartDate,
    TranslateModule,
    MatHint,
    MatDatepicker,
    MatDatepickerInput,
    MatInput,
    MatLabel,
    MatIcon,
    NgIf
  ],
  templateUrl: './date-time-form.component.html',
  styleUrls: ['./date-time-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeFormComponent),
      multi: true
    }
  ]
})
export class DateTimeFormComponent implements OnInit, ControlValueAccessor, Validators, AfterContentInit {

  formGroup: FormGroup = this.formBuilder.group( {
    date: [],
    time: []
  })

  onChange: any = () => {};
  onTouched: any = () => {};
  @Input() defaultTimeValue: string = "00:00"
  @Input() dateLabel: string = ""
  @Input() timeLabel: string = ""
  @Input() dateMin: Date | null = null
  @Input() dateMax: Date | null = null

  constructor(private formBuilder: FormBuilder) {}
  subscriptions: Subscription = new Subscription();


  ngOnInit() {
    this.onSubmit();
  }

  ngAfterContentInit() {
    const dateValue = this.formGroup.get('date')?.value
    dateValue ? this.formGroup.get('time')?.enable() : this.formGroup.get('time')?.disable()
  }

  onSubmit() {
    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(
        map((it : FormDateAndHourType): FormDateAndHourType => {
          let date : FormDateAndHourType = null
          if (it?.date && !it?.time) {
            date = {date: it.date, time: this.defaultTimeValue}
            this.formGroup.get('time')?.patchValue(this.defaultTimeValue, {emitEvent: false, onlySelf: true})
            this.formGroup.get('time')?.enable()
          } else if (!it?.date && it?.time) {
            date = null
            this.formGroup.get('time')?.patchValue(null, {emitEvent: false, onlySelf: true})
            this.formGroup.get('time')?.disable()
          } else if (it?.date && it?.time) {
            date = it
          } else {
            // do nothing
          }
          return date
        })

      ).subscribe((it: FormDateAndHourType) => this.onChange(this.formatDate(it)))
    )
  }

  showPicker(inputElement: HTMLInputElement): void {
    if ('showPicker' in HTMLInputElement.prototype) {
      try {
        inputElement.showPicker();
      } catch (error) {
        console.error('Error showing picker:', error);
      }
    } else {
      console.warn('showPicker() is not supported in this browser.');
    }
  }

  formatDate(it: FormDateAndHourType): Date | null {
    let myDate: Date | null = null
    if (it?.date) {
      myDate = new Date(it.date)
    }
    if (it?.time && myDate) {
      myDate.setHours(Number(it.time.split(":")[0]), Number(it.time.split(":")[1]))
    }

    return myDate;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
     isDisabled ? this.formGroup.disable() : this.formGroup.enable()
  }

  writeValue(obj?: Date): void { // work as an input
    if (obj) {
      this.formGroup.patchValue({
        date: obj,
        time: obj.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
      })
    }
  }

  removeValue() {
    this.formGroup.patchValue({
        date: null
    });
  }
}
