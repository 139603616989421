export class CollectionUtil {
  private static regExpAlpha: RegExp = new RegExp("[^a-zA-Z]", "g")
  private static regExpNumeric: RegExp = new RegExp("[^0-9]", "g")

  constructor() { }

  static setsEqual(set1: any, set2: any): boolean {
    if (set1.size !== set2.size) return false
    for (const elt of set1) if (!set2.has(elt)) return false
    return true;
  }

  static sortAlphaNumeric(arrayToSort: string[]): string[] {
    if (arrayToSort) {
      return arrayToSort.sort(this.compareAlphaNums)
    } else {
      return arrayToSort
    }
  }

  static sortAlphaNumericProp(arrayToSort: any[], property: string): any[] {
    if (arrayToSort) {
      return arrayToSort.sort(this.compareAlphaNumsProp(property))
    } else {
      return arrayToSort
    }
  }

  private static compareAlphaNums(a: string, b: string) {
    var aN = parseInt(a.replace(CollectionUtil.regExpNumeric, ""), 10)
    var bN = parseInt(b.replace(CollectionUtil.regExpNumeric, ""), 10)

    if (isNaN(aN) || isNaN(bN)) {
      return a.localeCompare(b)
    }

    const aNMinusbN = aN - bN
    if (aNMinusbN != 0) {
      return aNMinusbN
    } else {
      const aA = a.replace(CollectionUtil.regExpAlpha, "")
      const bA = b.replace(CollectionUtil.regExpAlpha, "")
      return aA.localeCompare(bA)
    }
  }

  private static compareAlphaNumsProp(property: string) {
    const compare = function (a: any, b: any) {
      return CollectionUtil.compareAlphaNums(a[property], b[property])
    }
    return compare
  }
}
