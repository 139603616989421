export enum TimeSlotType {
  WEEKLY = "WEEKLY",
  ONESHOT = "ONESHOT"
}

export enum DayOfWeek {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY"
}

export interface TimeSlot {
  type: TimeSlotType,
  allDay: boolean
}

export interface WeeklyTimeSlot extends TimeSlot {
  weekDays?: DayOfWeek[],
  startTime?: string,
  endTime?: string
}

export interface OneshotTimeSlot extends TimeSlot {
  start?: Date,
  end?: Date
}
