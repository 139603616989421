import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { CollectionUtil, ScopeService } from 'hcl-lib';
import { Subscription } from "rxjs";

export enum CmnIfPluginOperator {
  AND,
  OR
}

@Directive({
    selector: '[cmnIfPlugin]',
    standalone: true
})
export class CmnIfPluginDirective implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  private hasView = false;
  private pluginNames: Set<string> = new Set<string>();
  private operator: CmnIfPluginOperator = CmnIfPluginOperator.OR;
  private custPluginNames: string[] = []

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private scopeService: ScopeService
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.scopeService.getPluginNamesObservable().subscribe(custPluginNames => {
        this.custPluginNames = custPluginNames
        this.updateVisiblity()
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  @Input()
  set cmnIfPlugin(newPluginNames: string[]) {
    let newPluginNamesSet = new Set<string>();
    if (newPluginNames && newPluginNames.length > 0) {
      for (let newPluginName of newPluginNames) {
        newPluginNamesSet.add(newPluginName);
      }
    }

    if (CollectionUtil.setsEqual(newPluginNamesSet, this.pluginNames)) {
      return;
    }
    this.pluginNames = newPluginNamesSet;
    this.updateVisiblity();
  }

  @Input("cmnIfPluginOp")
  set cmnIfPluginOp(newOperatorStr: string) {
    let newOperator = newOperatorStr.toLowerCase() === 'and' ? CmnIfPluginOperator.AND : CmnIfPluginOperator.OR;
    if (newOperator === this.operator) {
      return;
    }

    this.operator = newOperator;
    this.updateVisiblity();
  }

  private updateVisiblity() {
    let custPluginNames = this.custPluginNames
    let pluginNames = this.pluginNames;
    let operator = this.operator;
    let hasOnePlugin = pluginNames.size == 0 || (custPluginNames.length > 0 && custPluginNames.find(pluginName => pluginNames.has(pluginName)) != null);
    let hasAllPlugins = pluginNames.size == 0 || Array.from(pluginNames).every(pluginName => custPluginNames.find(custPluginName => custPluginName === pluginName) != null);
    let showElt = operator === CmnIfPluginOperator.OR ? hasOnePlugin : hasAllPlugins;
    if (showElt && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!showElt && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
