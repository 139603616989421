import { trigger, transition, style, animate } from '@angular/animations';
import { NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-sort-select',
  templateUrl: 'sort-select.component.html',
  styleUrls: ['sort-select.component.scss'],
  animations: [
    trigger('direction', [
      transition('asc <=> desc', [
        style({
          transform: `scale(1.5)`,
          opacity: 0,
        }),
        animate('.2s 0s ease-out'),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    NgFor,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    UpperCasePipe,
  ],
})
export class SortSelectComponent {
  @Input() availableSorts!: Sort[];
  @Input() selectedSort!: Sort;

  @Output() selectionChange = new EventEmitter<Sort>();

  constructor() {}

  get direction(): 'asc' | 'desc' {
    switch (this.selectedSort.direction) {
      case 'asc':
        return 'asc';
      default:
        return 'desc';
    }
  }

  onSortChange(): void {
    this.selectionChange.emit(this.selectedSort);
  }

  onDirectionChange(): void {
    switch (this.selectedSort.direction) {
      case 'asc':
        this.selectedSort.direction = 'desc';
        break;
      default:
        this.selectedSort.direction = 'asc';
        break;
    }
    this.onSortChange();
  }
}
