<h1 class="scr-title" mat-dialog-title>{{ title }}</h1>

<form [formGroup]="directoryForm" (ngSubmit)="doSubmit()">
  <mat-dialog-content class="directory-dialog__content">
    <mat-form-field>
      <input matInput [placeholder]="'CUSTOMER.NAME' | translate" formControlName="name" required>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button class="hcl-button" mat-dialog-close type="button">{{'CANCEL' | translate}}</button>
    <button mat-button type="button" class="hcl-button-delete" *ngIf="directory.id"
      [disabled]="directoryMedias.length > 0 || directory.children?.length! > 0"
      [matTooltip]="'DIRECTORY.CANNOT_DELETE_TOOLTIP' | translate"
      [matTooltipDisabled]="directoryMedias.length == 0 && directory.children?.length == 0" (click)="doDelete()">
      {{'DELETE' | translate}}
    </button>
    <button mat-button type="submit" class="scr-button-confirm" [disabled]="!directoryForm.valid">
      {{ 'SAVE' | translate }}
    </button>
  </mat-dialog-actions>
</form>