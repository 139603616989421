export interface Tag {
  id?: string,
  customerId?: string,
  name: string,
  targetTypes: TargetType[],
  system: boolean
}

export enum TargetType {
  DEVICE = "DEVICE",
  MEDIA = "MEDIA",
  SCENARIO = "SCENARIO",
  DATASOURCES = "DATASOURCES",
  LOCATION = "LOCATION"
}
