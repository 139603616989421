import { NgFor, NgIf } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LegacyTemplates } from "projects/hcl-portal/src/app/common/interfaces/legacy-template";

@Component({
    selector: 'app-legacy-template-dialog',
    templateUrl: './legacy-template-dialog.component.html',
    styleUrls: ['./legacy-template-dialog.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, MatDialogModule, MatButtonModule, TranslateModule]
})
export class LegacyTemplateDialogComponent implements AfterViewInit {

  selectedTemplate?: string
  legacyTemplateDataSource = new MatTableDataSource<any>()

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<LegacyTemplateDialogComponent>,
    private router: Router
  ) { }

  ngAfterViewInit(): void {
    this.legacyTemplateDataSource.data = LegacyTemplates.filter(e => e.canCreate)
    this.cdr.detectChanges()
  }

  onSelect(legacyTemplate: string): void {
    this.selectedTemplate = legacyTemplate
  }

  doCreate(): void {
    if (this.selectedTemplate) {
      this.router.navigate(["screenlab/templates/" + this.selectedTemplate + "/legacy/create"])
    }
    this.dialogRef.close()
  }
}
