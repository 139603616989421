import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addSpaceBeforeColon',
    standalone: true
})
export class AddSpaceBeforeColonPipe implements PipeTransform {

  constructor(
    @Inject(LOCALE_ID) public locale: string
  ) {
    // do nothing
  }

  transform(value: string): string {
    return this.locale == "fr" ? value + " "  : value;
  }
}
