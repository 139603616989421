<div [formGroup]="formGroup" class="date-time-form">
  <mat-form-field class="date-time-form__date">
    <mat-label>{{ dateLabel }}</mat-label>
    <input matInput [min]="this.dateMin" [max]="this.dateMax" [matDatepicker]="inServiceDatePicker" formControlName="date" >
    <mat-datepicker-toggle matIconSuffix readonly [for]="inServiceDatePicker"></mat-datepicker-toggle>
    <mat-datepicker readonly #inServiceDatePicker></mat-datepicker>
  </mat-form-field>


  <mat-form-field class="date-time-form__time">
    <mat-label>{{ timeLabel }}</mat-label>
    <input #time matInput type="time" formControlName="time">
    <mat-icon class="date-time-form__time__icon" (click)="showPicker(time)">schedule</mat-icon>
  </mat-form-field>

  <mat-icon class="date-time-form__close-icon" aria-label="Clear"
            (click)="removeValue()">close</mat-icon>
</div>
