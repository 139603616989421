<h1 class="scr-title" mat-dialog-title>
  {{ 'MEDIA.REENCODE.TITLE' | translate }}
</h1>
<mat-dialog-content class="media-reencode-dialog__content">
  <form [formGroup]="reencodeForm" class="media-reencode-dialog__content__form">
    <mat-form-field>
      <mat-label>{{ "MEDIA.REENCODE.MAX_CPU_IN_M.LABEL" | translate }}</mat-label>
      <input matInput type="number" formControlName="maxCpu"
        [placeholder]="'MEDIA.REENCODE.MAX_CPU_IN_M.LABEL' | translate">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{ "MEDIA.REENCODE.MAX_MEM_IN_MO.LABEL" | translate }}</mat-label>
      <input matInput type="number" formControlName="maxMem"
        [placeholder]="'MEDIA.REENCODE.MAX_MEM_IN_MO.LABEL' | translate">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="hcl-button" mat-button [mat-dialog-close]="true" type="button">
    {{ 'CANCEL' | translate }}
  </button>
  <button class="scr-button-confirm" mat-button (click)="doReencode()" [disabled]="reencodeForm.invalid">
    {{ 'REENCODE' | translate }}
  </button>
</mat-dialog-actions>