import { Directive, OnInit } from "@angular/core";
import { MatInput } from "@angular/material/input";

@Directive({
  selector: '[cmnInputAutoFocus]',
  standalone: true
})
export class CmnInputAutoFocusDirective implements OnInit {

  constructor(private matInput: MatInput) {
    // do nothing
  }

  ngOnInit(): void {
    this.matInput.focus()
  }
}