import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Customer, CustomerNames, CustomerService, ScopeService } from 'hcl-lib';
import { Subscription } from 'rxjs';
import { RecentSearchSelectComponent } from '../recent-search-select/recent-search-select.component';

@Component({
    selector: 'app-customer-recent-search-select',
    templateUrl: './customer-recent-search-select.component.html',
    styleUrls: ['./customer-recent-search-select.component.scss'],
    standalone: true,
    imports: [RecentSearchSelectComponent]
})
export class CustomerRecentSearchSelectComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  selectedCustomer!: Customer
  recentCustomers: Customer[] = []
  foundCustomers: CustomerNames[] = []

  @Input() placeholder: string = "Select one choice"
  @Input() initialCustomer: Customer | null = null
  @Output() selectedCustomerChange = new EventEmitter<Customer>()

  constructor(
    private scopeService: ScopeService,
    private customerService: CustomerService
  ) {
    // do nothing
   }

  ngOnInit(): void {
    if (this.initialCustomer) {
      this.selectedCustomer = this.initialCustomer
    }
    this.retrieveRecentCustomers()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  retrieveRecentCustomers(): void {
    this.subscriptions.add(
      this.scopeService.getRecentCustomers().subscribe(recentCustomers => {
        this.recentCustomers = recentCustomers
      })
    )
  }

  selectCustomer(customerNames: CustomerNames): void {
    this.subscriptions.add(
      this.customerService.getCustomer(customerNames.id as string).subscribe(customer => {
          this.selectedCustomer = customer
          this.selectedCustomerChange.emit(customer)
      })
    )
  }

  searchCustomer(search: string): void {
    this.subscriptions.add(
      this.customerService.getAvailableCustomers(0, 5, search).subscribe(foundCustomers => {
        this.foundCustomers = foundCustomers
      })
    )
  }
}
