<h1 class="scr-title" mat-dialog-title>
  {{ actionLabel }} {{ oneMediaTypeName }}
</h1>

<mat-dialog-content>

  <mat-tab-group class="media-picker-dialog__tabs" mat-stretch-tabs="false">

    <!-- MY_MEDIAS -->
    <mat-tab>
      <ng-template mat-tab-label>
        {{ "MEDIA_LIST.MY_MEDIAS.TAB" | translate | titlecase }}
      </ng-template>
      <ng-template matTabContent>
        <app-media-list-widget [title]="'MEDIA_LIST.MY_MEDIAS.TITLE' | translate"
          [mediaListWidgetType]="MediaListWidgetType.MY_MEDIAS" [allowedMediaTypes]="allowedMediaTypes"
          [restrictToAppIds]="restrictToAppIds" [selectMode]="true" [showMediaCreateButton]="true"
          [showTemplateCreateButton]="false" (mediaSelected)="onMediaSelected($event)"
          (mediaImported)="onMediaImported($event)">
        </app-media-list-widget>
      </ng-template>
    </mat-tab>

    <!-- PUBLIC_MEDIAS -->
    <ng-container *ngIf="showPublicMediasTab">
      <mat-tab *cmnIfPerm="['scr:public-media:read']">
        <ng-template mat-tab-label>
          {{ "MEDIA_LIST.PUBLIC_MEDIAS.TAB" | translate | titlecase }}
        </ng-template>
        <ng-template matTabContent>
          <app-media-list-widget [title]="'MEDIA_LIST.PUBLIC_MEDIAS.TITLE' | translate"
            [mediaListWidgetType]="MediaListWidgetType.PUBLIC_MEDIAS" [allowedMediaTypes]="allowedPublicMediaTypes"
            [restrictToAppIds]="restrictToAppIds" [selectMode]="true" [showMediaCreateButton]="true"
            [showTemplateCreateButton]="false" (mediaSelected)="onMediaSelected($event)"
            (mediaImported)="onMediaImported($event)">
          </app-media-list-widget>
        </ng-template>
      </mat-tab>
    </ng-container>

    <!-- ACTIVITY_MEDIAS -->
    <ng-container *ngIf="showActivityMediasTab">
      <mat-tab *cmnIfPerm="['scr:public-media:read']">
        <ng-template mat-tab-label>
          {{ "MEDIA_LIST.ACTIVITY_MEDIAS.TAB" | translate | titlecase }}
          {{ "DOMAIN." + customer.domain | translate | titlecase }}
        </ng-template>
        <ng-template matTabContent>
          <app-media-list-widget [title]="'MEDIA_LIST.ACTIVITY_MEDIAS.TITLE' | translate"
            [mediaListWidgetType]="MediaListWidgetType.ACTIVITY_MEDIAS" [allowedMediaTypes]="allowedPublicMediaTypes"
            [restrictToAppIds]="restrictToAppIds" [selectMode]="true" [showMediaCreateButton]="true"
            [showTemplateCreateButton]="false" (mediaSelected)="onMediaSelected($event)"
            (mediaImported)="onMediaImported($event)">
          </app-media-list-widget>
        </ng-template>
      </mat-tab>
    </ng-container>

  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions class="media-picker-dialog__actions">
  <div class="media-picker-dialog__actions__conditions-of-use">
    <span>
      {{ 'MEDIA.IMPORT_YOUR' | translate }} {{ mediaTypeNamePlural }} {{ 'MEDIA.AGREE_TO_MODERATION_TERMS' | translate
      }}
      {{ manyMediaTypeName }}
    </span>
  </div>
  <div class="media-picker-dialog__actions__buttons">
    <button mat-button type="button" class="hcl-button" mat-dialog-close>
      {{ 'CANCEL' | translate }}
    </button>
    <button mat-button type="button" class="scr-button-confirm" (click)="doConfirm()" [disabled]="!selectedMedia">
      <mat-icon>add</mat-icon>
      <span>{{ actionLabel }}</span>
      <span class="hide-mobile"> {{ theMediaTypeName }}</span>
    </button>
  </div>
</mat-dialog-actions>