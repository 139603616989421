import { TimeSlot } from 'hcl-lib';
import { DeviceStatus } from './device-status';
import { DeviceSvdConfig } from './device-svd-config';
import { KioskConfig } from './kiosk-config';
import { PeripheralConfig } from "./peripheral-config";

export interface Device {
  id: string
  customerId: string
  name: string
  type: DeviceType
  orientation: DeviceOrientation
  muted: boolean
  version?: string
  versionId?: string
  serialNumber?: string
  ipAddress?: string
  macAddresses?: string[]
  comment?: string
  legacyScreenlabUser?: string
  pairingCode?: string
  tagIds?: string[]
  excludedTagIds?: string[]
  kioskConfig?: KioskConfig
  status?: DeviceStatus
  autoPowerOnOff?: boolean
  remoteControlConfigs: DeviceRemoteControlConfig[]
  peripheralConfig?: PeripheralConfig
  useRealTime?: boolean
  svdConfig?: DeviceSvdConfig
  openingTimeSlots?: TimeSlot[]
  useUpdatingTimeSlots: boolean
  updatingTimeSlots?: TimeSlot[],
  language?: string,
  zoneId?: any
}

export enum DeviceType {
  SCR_ELECTRON = "SCR_ELECTRON",
  SCR_RASPBERRY_PI = "SCR_RASPBERRY_PI",
  SCR_X86 = "SCR_X86",
  SCR_LG = "SCR_LG",
  SCR_ANDROID = "SCR_ANDROID",
  SCR_TIZEN = "SCR_TIZEN",
  SCR_SCREENSAVER = "SCR_SCREENSAVER",
  KIOSK = "KIOSK",
  IMMOTOUCH = "IMMOTOUCH"
}

export enum DeviceOrientation {
  LANDSCAPE = "LANDSCAPE",
  PORTRAIT = "PORTRAIT",
  REVERSE_LANDSCAPE = "REVERSE_LANDSCAPE",
  REVERSE_PORTRAIT = "REVERSE_PORTRAIT"
}

export interface DeviceEventsSummary {
  usages: {
    from: string,
    to: string,
    durationInSeconds: number,
    sessions: number
  }[]
}

export type DeviceRemoteControlConfig = DeviceTeamViewerConfig | DeviceVpnConfig | DeviceWindowsConfig

export class DeviceTeamViewerConfig {
  readonly type: string = "teamviewer"
  description?: string
  id!: string
  password!: string
}

export class DeviceVpnConfig {
  readonly type: string = "vpn"
  description?: string
  hostname!: string
  login!: string
  password!: string
}

export class DeviceWindowsConfig {
  readonly type: string = "windows"
  description?: string
  login!: string
  password!: string
}

export enum DeviceSupportedLanguage {
  FRENCH = "fr",
  ENGLISH = "en"
}
