import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

export interface MediaFullSizeDialogData {
  mediaUrl: string;
}

@Component({
    selector: 'app-media-fullsize-dialog',
    templateUrl: './media-fullsize-dialog.component.html',
    styleUrls: ['./media-fullsize-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogModule, MatButtonModule, MatIconModule]
})
export class MediaFullsizeDialogComponent implements OnInit {

  mediaUrl!: string

  constructor(
    private dialogRef: MatDialogRef<MediaFullsizeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaFullSizeDialogData
  ) {
    // do nothing
   }

  ngOnInit(): void {
    this.mediaUrl = this.data.mediaUrl
  }

  close() {
    this.dialogRef.close();
  }

}
