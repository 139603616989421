export enum Domain {
  CRAFTSMAN = "CRAFTSMAN",
  INSURANCE = "INSURANCE",
  CAR = "CAR",
  BANK = "BANK",
  BAR = "BAR",
  BUTCHER = "BUTCHER",
  BAKERY = "BAKERY",
  CONSTRUCTION = "CONSTRUCTION",
  WINE_MERCHANT = "WINE_MERCHANT",
  EDUCATION = "EDUCATION",
  ENERGY = "ENERGY",
  COMMUNICATION = "COMMUNICATION",
  FINANCE = "FINANCE",
  FITNESS = "FITNESS",
  SOFTWARE_EDITOR = "SOFTWARE_EDITOR",
  LARGE_RETAILERS = "LARGE_RETAILERS",
  TOURISM = "TOURISM",
  REAL_ESTATE = "REAL_ESTATE",
  PHARMACEUTICAL = "PHARMACEUTICAL",
  OPTICIAN = "OPTICIAN",
  FUNERAL_SERVICES = "FUNERAL_SERVICES",
  CATERING = "CATERING",
  HEALTH = "HEALTH",
  BEAUTY = "BEAUTY",
  HAIRDRESSING = "HAIRDRESSING",
  TELECOMMUNICATION = "TELECOMMUNICATION",
  TEXTILE = "TEXTILE",
  LOGISTIC = "LOGISTIC",
  OTHER = "OTHER"
}
