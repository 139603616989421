<button mat-button onclick="blur()" (click)="openPanel()" type="button" #origin class="recent-search-select__action"
        [matTooltip]="selectedPrimary + (selectedSecondary ? '\n' + selectedSecondary : '')"
        matTooltipPosition="below"
        matTooltipClass="recent-search-select__action__tooltip"
>
  <div class="recent-search-select__action__container">
    <div class="current_choice__content">
     <span [class]="headerClass" class="current_choice__selected-primary">
       {{ selectedPrimary }}
      </span>
      <span class="current_choice__selected-secondary" *ngIf="selectedSecondary">
        {{ selectedSecondary }}
      </span>
    </div>
    <mat-icon>expand_more</mat-icon>
  </div>
</button>
<ng-template cdk-portal #panel="cdkPortal">
  <div class="recent-search-select__panel" cdkTrapFocus cdkTrapFocusAutoCapture>
    <mat-form-field class="recent-search-select__panel__search">
      <input matInput cmnInputAutoFocus type="text" [(ngModel)]="search" (input)="onSearch()" autocomplete="off">
      <mat-icon *ngIf="search" aria-label="Clear" (click)="clear()">close</mat-icon>
      <mat-icon *ngIf="!search" (click)="onSearch()">search</mat-icon>
    </mat-form-field>
    <mat-divider></mat-divider>
    <ng-container *ngIf="search || recentChoices.length === 0; else recent">
      <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" (selectionChange)="onSelect($event)"
                          class="recent-search-select__panel__selection">
        <div mat-subheader class="recent-search-select__panel__selection__header">
          <mat-icon>search</mat-icon>
          <span>{{"RESULTS" | translate}}</span>
        </div>
        <mat-list-option [hidden]="isLoadingResults || isDebouncing" *ngFor="let choice of foundChoices"
          [value]="choice">
          <div class="choice-option">
            <span class="choice-option__primary">
              {{ choice.displayName ? (choice.displayName == choice.name) ? choice.displayName : choice.displayName : choice.name  }}
            </span>
            <span class="choice-option__secondary" *ngIf="choice.name && choice.displayName && choice.displayName !== choice.name">
              {{ choice.name  }}
            </span>
          </div>
        </mat-list-option>
        <div *ngIf="isLoadingResults || isDebouncing" class="recent-search-select__panel__selection__header__spinner">
          <mat-spinner color="primary" mode="indeterminate" [diameter]="50">
          </mat-spinner>
        </div>
      </mat-selection-list>
    </ng-container>
    <ng-template #recent>
      <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true" (selectionChange)="onSelect($event)"
        class="recent-search-select__panel__selection">
        <div mat-subheader class="recent-search-select__panel__selection__header">
          <mat-icon>history</mat-icon>
          <span>{{"RECENT" | translate}}</span>
        </div>
        <mat-list-option *ngFor="let choice of recentChoices" [value]="choice">
          <div class="choice-option">
            <span class="choice-option__primary">
              {{ choice.displayName ? (choice.displayName == choice.name) ? choice.displayName : choice.displayName : choice.name  }}
            </span>
            <span class="choice-option__secondary" *ngIf="choice.name && choice.displayName && choice.displayName !== choice.name">
              {{ choice.name  }}
            </span>
          </div>

        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </div>
</ng-template>
