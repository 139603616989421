<div class="sort-select">
  <span class="sort-select__label">{{ "SORT.LABEL" | translate }}</span>
  <mat-form-field class="sort-select__form-field">
    <mat-select [(value)]="selectedSort" (selectionChange)="onSortChange()">
      <mat-option *ngFor="let sort of availableSorts" [value]="sort">
        {{ "SORT.FIELD." + sort.active | uppercase | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button class="sort-select__direction" mat-icon-button [@direction]="direction"
    [matTooltip]="'SORT.TOOLTIP.' + direction | uppercase | translate" [matTooltipPosition]="'above'"
    (click)="onDirectionChange()">
    <ng-container [ngSwitch]="direction">
      <mat-icon *ngSwitchCase="'asc'">north</mat-icon>
      <mat-icon *ngSwitchDefault>south</mat-icon>
    </ng-container>
  </button>
</div>
