import { LOCATION_INITIALIZED } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { APP_INITIALIZER, Injector, NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from "./multi-translate-http-loader";

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/", suffix: ".55921dc6-f623-450c-b1b7-dd29850b100d.json" },
    { prefix: "./assets/i18n/hcl-lib/", suffix: ".55921dc6-f623-450c-b1b7-dd29850b100d.json" },
  ]);
}

export const translateModule = TranslateModule.forRoot({
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
})

@NgModule({
  imports: [
    translateModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],
  exports: [TranslateModule]
})
export class TranslationModule { }

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  let initializer = () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      translate.setDefaultLang('fr');
      translate.use(translate.defaultLang).subscribe(() => {
      }, error => {
        console.error(`Problem with '${translate.defaultLang}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
  return initializer
}
