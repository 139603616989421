
export enum LegacyTemplateTextFontFamily {
  'Arial',
  'Arial Black',
  'Anton',
  'Arimo',
  'Arvo',
  'Asap',
  'Caviar Dreams',
  'Champagne & Limousines',
  'Changa One',
  'Comfortaa',
  'Comic Sans MS',
  'Cookie',
  'Courier New',
  'Dancing Script',
  'DejaVu Sans',
  'Didact Gothic',
  'Galada',
  'Georgia',
  'Impact',
  'Imprima',
  'Josefin Slab',
  'Liberation Sans',
  'Liberation Sans Narrow',
  'Lobster',
  'Lobster Two',
  'Montserrat',
  'Old Standard TT',
  'Open Sans',
  'Patua One',
  'Questrial',
  'Roboto',
  'SourceSansPro',
  'Times New Roman',
  'Trebuchet MS',
  'Verdana'
}

export enum LegacyTemplateMediaMode {
  ADJUST = 'PreserveAspectFit',
  FILL = 'PreserveAspectCrop',
  STRETCH = 'Stretch',
  PAD = 'Pad'
}

export enum LegacyTemplateMediaPosition {
  CENTER = 'AlignHCenter',
  LEFT = 'AlignLeft',
  RIGHT = 'AlignRight'
}

export enum LegacyTemplateTransition {
  NONE = '',
  FADE_IN = 'fadeIn();',
  SLIDE_RIGHT = 'slideRight();',
  SLIDE_LEFT = 'slideLeft();',
  SLIDE_BOTTOM = 'slideBottom();',
  SLIDE_TOP = 'slideTop();',
  SLIDE_BOTTOM_RIGHT = 'slideRight();slideBottom();',
  SLIDE_BOTTOM_LEFT = 'slideLeft();slideBottom();',
  SLIDE_TOP_RIGHT = 'slideRight();slideTop();',
  SLIDE_TOP_LEFT = 'slideLeft();slideTop();',
  ROTATE_RIGHT = 'slideRight();rotate();',
  ROTATE_LEFT = 'slideLeft();rotate();',
  ROTATE_BOTTOM = 'slideBottom();rotate();',
  ROTATE_TOP = 'slideTop();rotate();',
  ROTATE_BOTTOM_RIGHT = 'slideRight();slideBottom();rotate();',
  ROTATE_BOTTOM_LEFT = 'slideLeft();slideBottom();rotate();',
  ROTATE_TOP_RIGHT = 'slideRight();slideTop();rotate();',
  ROTATE_TOP_LEFT = 'slideLeft();slideTop();rotate();'
}

export enum LegacyTemplateMediaTextType {
  TEXT = 'text',
  MEDIA = 'logo'
}

export enum LegacyTemplateBackgroundType {
  COLOR = 'color',
  MEDIA = 'image'
}

export enum LegacyTemplateOrientation {
  LANDSCAPE = "horizontal",
  PORTRAIT = "vertical"
}

export const LegacyTemplates = [
  {
    format: "simple",
    canCreate: false,
    isLandscape: true,
    isPortrait: true,
    schema: {
      header: {
        type: "old-header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      medias_main: {
        type: "old-media-block",
        translationKey: "MEDIA_MAIN"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "simple2",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      medias_main: {
        type: "media-block",
        translationKey: "MEDIA_MAIN"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "double",
    canCreate: false,
    isLandscape: true,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      medias_left: {
        type: "media-block",
        translationKey: "MEDIA_LEFT"
      },
      medias_right: {
        type: "media-block",
        translationKey: "MEDIA_RIGHT"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "double2",
    canCreate: true,
    isLandscape: true,
    isPortrait: false,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      left: {
        type: "media-or-text-block",
        translationKey: "CONTENT_LEFT"
      },
      right: {
        type: "media-or-text-block",
        translationKey: "CONTENT_RIGHT"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "doublevertical",
    canCreate: true,
    isLandscape: false,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      top: {
        type: "media-or-text-block",
        translationKey: "MEDIA_FIRST"
      },
      bottom: {
        type: "media-or-text-block",
        translationKey: "MEDIA_SECOND"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "doubleprix",
    canCreate: true,
    isLandscape: false,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      medias_left: {
        type: "media-price-block",
        translationKey: "CONTENT_LEFT"
      },
      medias_right: {
        type: "media-price-block",
        translationKey: "CONTENT_RIGHT"
      },
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  },
  {
    format: "pharma",
    canCreate: false,
    isLandscape: true,
    isPortrait: true,
    schema: {
      medias_big: {
        type: "old-media-block",
        translationKey: "MEDIA_BIG"
      },
      medias_little: {
        type: "old-media-block",
        translationKey: "MEDIA_LITTLE"
      },
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      }
    }
  },
  {
    format: "pharma2",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      medias_big: {
        type: "media-block",
        translationKey: "MEDIA_BIG"
      },
      medias_little: {
        type: "media-block",
        translationKey: "MEDIA_LITTLE"
      },
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      }
    }
  },
  {
    format: "textsimple",
    canCreate: false,
    isLandscape: true,
    isPortrait: true,
    schema: {
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      }
    }
  },
  {
    format: "textsimple2",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      }
    }
  },
  {
    format: "textht",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      }
    }
  },
  {
    format: "quadruplemediastext",
    canCreate: false,
    isLandscape: true,
    isPortrait: true,
    schema: {
      medias_first: {
        type: "old-media-block",
        translationKey: "MEDIA_FIRST"
      },
      infos_first: {
        type: "text-block",
        translationKey: "INFOS_FIRST"
      },
      medias_second: {
        type: "old-media-block",
        translationKey: "MEDIA_SECOND"
      },
      infos_second: {
        type: "text-block",
        translationKey: "INFOS_SECOND"
      },
      medias_third: {
        type: "old-media-block",
        translationKey: "MEDIA_THIRD"
      },
      infos_third: {
        type: "text-block",
        translationKey: "INFOS_THIRD"
      },
      medias_fourth: {
        type: "old-media-block",
        translationKey: "MEDIA_FOURTH"
      },
      infos_fourth: {
        type: "text-block",
        translationKey: "INFOS_FOURTH"
      }
    }
  },
  {
    format: "quadruplemediastext2",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      medias_first: {
        type: "media-block",
        translationKey: "MEDIA_FIRST"
      },
      infos_first: {
        type: "text-block",
        translationKey: "INFOS_FIRST"
      },
      medias_second: {
        type: "media-block",
        translationKey: "MEDIA_SECOND"
      },
      infos_second: {
        type: "text-block",
        translationKey: "INFOS_SECOND"
      },
      medias_third: {
        type: "media-block",
        translationKey: "MEDIA_THIRD"
      },
      infos_third: {
        type: "text-block",
        translationKey: "INFOS_THIRD"
      },
      medias_fourth: {
        type: "media-block",
        translationKey: "MEDIA_FOURTH"
      },
      infos_fourth: {
        type: "text-block",
        translationKey: "INFOS_FOURTH"
      }
    }
  },
  {
    format: "quadtitle",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      titre: {
        type: "text-block",
        translationKey: "TITLE"
      },
      medias_first: {
        type: "media-block",
        translationKey: "MEDIA_FIRST"
      },
      infos_first: {
        type: "text-block",
        translationKey: "INFOS_FIRST"
      },
      medias_second: {
        type: "media-block",
        translationKey: "MEDIA_SECOND"
      },
      infos_second: {
        type: "text-block",
        translationKey: "INFOS_SECOND"
      },
      medias_third: {
        type: "media-block",
        translationKey: "MEDIA_THIRD"
      },
      infos_third: {
        type: "text-block",
        translationKey: "INFOS_THIRD"
      },
      medias_fourth: {
        type: "media-block",
        translationKey: "MEDIA_FOURTH"
      },
      infos_fourth: {
        type: "text-block",
        translationKey: "INFOS_FOURTH"
      }
    }
  },
  {
    format: "prix",
    canCreate: true,
    isLandscape: true,
    isPortrait: true,
    schema: {
      header: {
        type: "header-block",
        translationKey: "HEADER"
      },
      medias_main: {
        type: "media-block",
        translationKey: "MEDIA_FIRST"
      },
      medias_prix: {
        type: "media-and-text-block",
        translationKey: "MEDIA_SECOND"
      },
      infos: {
        type: "text-block",
        translationKey: "INFOS"
      },
      footer: {
        type: "text-block",
        translationKey: "FOOTER"
      }
    }
  }
]
