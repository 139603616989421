import { CdkDragDrop, DragDropModule } from "@angular/cdk/drag-drop"
import { NestedTreeControl } from "@angular/cdk/tree"
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog"
import { MatRadioChange } from "@angular/material/radio"
import { MatTableDataSource } from "@angular/material/table"
import { TranslateModule, TranslateService } from "@ngx-translate/core"
import { CmnIfPermDirective, Domain, QueryScope, ScopeService } from 'hcl-lib';
import { Subscription, zip } from "rxjs"
import { Directory } from "../../../../interfaces/directory"
import { Media } from "../../../../interfaces/media"
import { DirectoryService } from "../../../../services/directory/directory.service"
import { MediaService } from "../../../../services/media/media.service"
import { DirectoryDialogComponent } from "../directory-dialog/directory-dialog.component"
import { MediaDomainFilterEnum } from '../../../../interfaces/media-domain-filter-enum';
import { MediaListWidgetType } from "../media-list/media-list-widget-type";
import { map, mergeMap } from "rxjs/operators";
import { NgFor, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { MatTreeModule } from "@angular/material/tree";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";

export enum DirectoryMode {
  ALL = "ALL",
  TREE = "TREE"
}

@Component({
    selector: "app-directory",
    templateUrl: "./directory.component.html",
    styleUrls: ["./directory.component.scss"],
    standalone: true,
    imports: [NgFor, NgIf, FormsModule, MatRadioModule, MatTreeModule, MatButtonModule, MatIconModule, DragDropModule, TranslateModule, CmnIfPermDirective]
})
export class DirectoryComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  @Output() directoryFilter: EventEmitter<boolean> = new EventEmitter()
  @Output() directoryId: EventEmitter<Directory> = new EventEmitter()
  @Output() mediaDropped: EventEmitter<string> = new EventEmitter()
  @Output() shareTreeControl: EventEmitter<{
    treeControl: NestedTreeControl<Directory>,
    directoryMode: DirectoryMode,
    directoryDataSource: MatTableDataSource<Directory>
  }> = new EventEmitter()

  @Input() mediaListWidgetType: MediaListWidgetType = MediaListWidgetType.MY_MEDIAS

  DirectoryMode = DirectoryMode
  directoryMode: DirectoryMode = DirectoryMode.ALL
  directoryModeKeys = Object.keys(DirectoryMode).filter(value => isNaN(Number(value)))

  scopeCid!: string
  directoryDataSource = new MatTableDataSource<Directory>()
  treeControl = new NestedTreeControl<Directory>(node => node.children)

  selectedDirectory?: Directory

  constructor(
    private directoryService: DirectoryService,
    private matDialog: MatDialog,
    private mediaService: MediaService,
    private scopeService: ScopeService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.retrieveDirectories()
    this.scopeCid = this.scopeService.getScope()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  sortByName(a: Directory, b: Directory) {
    if (typeof a.name === 'string' && typeof b.name === 'string') {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
    }
    return 0
  }

  retrieveDirectories(): void {

    let selectedScope = QueryScope.CUSTOMER
    let mediaDomainFilter: MediaDomainFilterEnum | undefined = undefined
    let isPublic = false

    switch (this.mediaListWidgetType) {
      case MediaListWidgetType.MY_MEDIAS:
        selectedScope = QueryScope.CUSTOMER
        mediaDomainFilter = undefined
        isPublic = false
        break
      case MediaListWidgetType.PUBLIC_MEDIAS:
        selectedScope = QueryScope.ANCESTORS
        mediaDomainFilter = MediaDomainFilterEnum.ONLY_WITHOUT_DOMAIN
        isPublic = true
        break
      case MediaListWidgetType.ACTIVITY_MEDIAS:
        selectedScope = QueryScope.ANCESTORS
        mediaDomainFilter = MediaDomainFilterEnum.EXCLUDE_WITHOUT_DOMAIN
        isPublic = true
        break
    }

    this.subscriptions.add(
      zip(
        this.scopeService.getScopeCustomer(),
        this.scopeService.hasPermission('scr:media:filter:domains')
      ).pipe(
        map(([customer, hasPermission]) => {
          if (!hasPermission) {
            return []
          }
          if (this.mediaListWidgetType === MediaListWidgetType.PUBLIC_MEDIAS) {
            return []
          }
          if (this.mediaListWidgetType === MediaListWidgetType.ACTIVITY_MEDIAS) {
            return [customer?.domain]
          }
          return Object.keys(Domain)
        }),
        mergeMap(domains => {
          return this.directoryService.getDirectories(
            selectedScope,
            mediaDomainFilter,
            domains as Domain[],
            isPublic
          )
        })
      ).subscribe(directories => {
        const tree = this.directoryService.directoriesToTree(directories)
        const rootDirectory: Directory = {
          name: "",
          children: tree
        }
        this.directoryDataSource.data = [rootDirectory]
        this.treeControl.expand(rootDirectory)
        this.onNodeClick(rootDirectory)
      })
    )
  }

  hasChild(_: number, node: Directory) {
    return !!node.children && node.children.length > 0
  }

  refresh() {
    const data = this.directoryDataSource.data
    this.directoryDataSource.data = []
    this.directoryDataSource.data = data
  }

  createSubdirectory(node: Directory) {
    const newDirectory: Directory = {
      name: "",
      parentId: node.id
    }
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("DIRECTORY.CREATE_TITLE"),
      directory: newDirectory
    }
    const dialogRef = this.matDialog.open(DirectoryDialogComponent, config)
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(directory => {
        if (directory) {
          directory.children = []
          node.children?.push(directory)
          this.refresh()
        }
      })
    )
  }

  editDirectory(node: Directory) {
    const config = new MatDialogConfig()
    config.data = {
      title: this.translateService.instant("DIRECTORY.EDIT_TITLE"),
      directory: node
    }
    const dialogRef = this.matDialog.open(DirectoryDialogComponent, config)
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe(directory => {
        if (directory) {
          node = directory
          this.refresh()
        } else if (directory === false) {
          this.retrieveDirectories()
        }
      })
    )
  }

  onRadioChange(event: MatRadioChange) {
    if (event.value === DirectoryMode.TREE) {
      this.directoryFilter.emit(true)
      this.directoryId.emit(this.selectedDirectory)
    } else {
      this.directoryFilter.emit(false)
    }
    this.shareTreeControl.emit({
      treeControl: this.treeControl,
      directoryMode: event.value,
      directoryDataSource: this.directoryDataSource
    })
  }

  onNodeClick(node: Directory) {
    this.selectedDirectory = node
    if (this.directoryMode === DirectoryMode.TREE) {
      this.directoryId.emit(this.selectedDirectory)
      this.shareTreeControl.emit({
        treeControl: this.treeControl,
        directoryMode: this.directoryMode,
        directoryDataSource: this.directoryDataSource
      })

    }
  }

  onDrop(event: CdkDragDrop<{ data: Media }[]>, node: Directory) {
    const drop = event.previousContainer.data[event.previousIndex]
    if (drop) {
      const media = drop.data
      media.directoryId = node.id
      this.subscriptions.add(
        this.mediaService.updateMedia(media).subscribe(_ => {
          this.mediaDropped.emit(media.id)
        })
      )
    }
  }
}
