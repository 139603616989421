import { RealEstateAdType } from "../../real-estate/interfaces/ad"
import { ScenarioConstraint } from "./scenario-contraints"

export type Slide = BaseSlide | MediaSlide | NewsSlide | ScenarioSlide | TagMediaSlide | TagScenarioSlide | WeatherSlide | CustomPluginSlide

export interface BaseSlide {
  type: SlideType,
  duration?: number,
  transition?: Transition,
  validityStart?: Date,
  validityEnd?: Date,
  metadata?: Map<string, string[]>
}

export interface MediaSlide extends BaseSlide {
  mediaId: string
  useMediaDuration?: boolean
  muted?: boolean
  dataSourceId?: string
  useTaggedDatasource?: boolean
  tags?: string[],
  tagsOp?: "OR" | "AND"
}

export interface NewsSlide extends BaseSlide {
  url: string
}

export interface ScenarioSlide extends BaseSlide {
  scenarioId: string
  inclusionPolicy: SlideInclusionPolicy
  constraints: ScenarioConstraint[]
}

export interface TagMediaSlide extends BaseSlide {
  mediaMaxNumber: number
  tags: string[],
  tagsOp: "OR" | "AND"
  inclusionPolicy: SlideInclusionPolicy
  constraints: ScenarioConstraint[]
}

export interface TagScenarioSlide extends BaseSlide {
  scenarioMaxNumber: number
  tags: string[],
  tagsOp: "OR" | "AND"
  inclusionPolicy: SlideInclusionPolicy
  constraints: ScenarioConstraint[]
}

export interface WeatherSlide extends BaseSlide {
  city: string
}

export interface AutosphereWeatherSlide extends BaseSlide {
  city: string
}

export interface WeatherLpgSlide extends BaseSlide {
  city: string,
  style: LPGWeatherStyleEnum
}

export interface WebSlide extends BaseSlide {
  url: string
}

export interface RealEstateAdSlide extends BaseSlide {
  durationControl: RealEstateAdSlideDurationControl
  durationPerImageControl: RealEstateAdSlideDurationPerImageControl
  useCustomDurationPerImage: boolean
  durationPerImageInSec: number
  adSlideFormat: RealEstateAdSlideFormat
  adIds: string[]
}

export interface RealEstateAdsSlide extends BaseSlide {
  durationControl: RealEstateAdSlideDurationControl
  durationPerImageControl: RealEstateAdSlideDurationPerImageControl
  useCustomDurationPerImage: boolean
  durationPerImageInSec: number
  adSlideFormat: RealEstateAdSlideFormat
  adTypes: Set<RealEstateAdType>
  onlyExclusives: boolean
  inclusionPolicy: SlideInclusionPolicy
}

export interface AutomobileAdSlide extends BaseSlide {
  autoAdSlideFormat: AutomobileAdSlideFormat
  useDefaultDuration: boolean
  autoAdIds: string[]
}

export interface AutomobileAdsSlide extends BaseSlide {
  autoAdSlideFormat: AutomobileAdSlideFormat
}

export interface CustomPluginSlide extends BaseSlide {
  customSlideId: string
  inclusionPolicy: SlideInclusionPolicy
  constraints: ScenarioConstraint[]
}

export enum SlideType {
  EPHEMERIS = "EPHEMERIS",
  HOROSCOPE = "HOROSCOPE",
  MEDIA = "MEDIA",
  NEWS = "NEWS",
  SCENARIO = "SCENARIO",
  TAG_MEDIA = "TAG_MEDIA",
  TAG_SCENARIO = "TAG_SCENARIO",
  WEATHER = "WEATHER",
  AUTOSPHERE_WEATHER = "AUTOSPHERE_WEATHER",
  LPG_WEATHER = "LPG_WEATHER",
  REAL_ESTATE_AD = "REAL_ESTATE_AD",
  REAL_ESTATE_ADS = "REAL_ESTATE_ADS",
  WEB = "WEB",
  AUTOMOBILE_AD = "AUTOMOBILE_AD",
  AUTOMOBILE_ADS = "AUTOMOBILE_ADS",
  CUSTOM_PLUGIN = "CUSTOM_PLUGIN",
  HHO_WAYFINDING = "HHO_WAYFINDING",
  HHO_EVENT = "HHO_EVENT",
  OPINION_SYSTEM_RATING = "OPINION_SYSTEM_RATING",
  OPINION_SYSTEM_REVIEW = "OPINION_SYSTEM_REVIEW",
  AZIMUT = "AZIMUT"
}

export enum Transition {
  NONE = "NONE",
  FADE_IN = "FADE_IN",
  SLIDE_LEFT = "SLIDE_LEFT",
  SLIDE_TOP = "SLIDE_TOP",
  SLIDE_RIGHT = "SLIDE_RIGHT",
  SLIDE_BOTTOM = "SLIDE_BOTTOM",
  ROTATE_LEFT = "ROTATE_LEFT",
  ROTATE_TOP = "ROTATE_TOP",
  ROTATE_RIGHT = "ROTATE_RIGHT",
  ROTATE_BOTTOM = "ROTATE_BOTTOM"
}

export enum RealEstateAdSlideFormat {
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE",
  QUADRUPLE = "QUADRUPLE"
}

export enum RealEstateAdSlideDurationControl {
  DURATION_PER_IMAGE = "DURATION_PER_IMAGE",
  SLIDE_DURATION = "SLIDE_DURATION"
}

export enum RealEstateAdSlideDurationPerImageControl {
  FROM_SLIDE_DURATION = "FROM_SLIDE_DURATION",
  FIXED_DURATION = "FIXED_DURATION"
}

export enum AutomobileAdSlideFormat {
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE"
}

export enum LPGWeatherStyleEnum {
  MEDICAL = "MEDICAL",
  ESTHETIC = "ESTHETIC"
}

export enum SlideInclusionPolicy {
  ALL_AT_ONCE = "ALL_AT_ONCE",
  ALTERNATE = "ALTERNATE"
}

export function isMediaSlide(data: any): boolean {
  return data && data.type && data.type == SlideType.MEDIA
}
