import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { EncoderConfig, Media } from "../../../interfaces/media";
import { MediaService } from "../../../services/media/media.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";

interface ReencodeMediaData {
  media: Media
}

@Component({
  selector: 'app-media-reencode-dialog',
  templateUrl: './media-reencode-dialog.component.html',
  styleUrls: ['./media-reencode-dialog.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatButtonModule, TranslateModule]
})
export class MediaReencodeDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  reencodeForm!: UntypedFormGroup
  media!: Media

  constructor(
    public dialogRef: MatDialogRef<MediaReencodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ReencodeMediaData,
    private formBuilder: UntypedFormBuilder,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    this.media = this.data.media
    this.reencodeForm = this.formBuilder.group({
      maxCpu: [null, Validators.compose([Validators.min(200), Validators.max(1800)])],
      maxMem: [null, Validators.compose([Validators.min(500), Validators.max(3200)])]
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  doReencode(): void {
    let maxCpu = this.reencodeForm.getRawValue().maxCpu
    let maxMem = this.reencodeForm.getRawValue().maxMem
    let encoderConfig: EncoderConfig | null = {
      maxCpu: maxCpu + "m",
      maxMem: maxMem + "Mi"
    }
    this.mediaService.forceEncodeMedia(this.media.id, encoderConfig).subscribe(_ => {
      this.dialogRef.close()
    })
  }
}
