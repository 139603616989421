<div class="media-tile" [class.media-tile--hide-border]="!showBorder" [matTooltip]="this.mediaInfos"
  [matTooltipPosition]="'above'" [matTooltipClass]="'media-tile__tooltip'" [matTooltipDisabled]="!showTooltip">
  <div *ngIf="media" class="media-tile__content">
    <div *ngIf="media.status == 'Failed'" class="media-tile__content__top">
      <div class="media-tile__content__top__badge">
        <img [matTooltip]="'MEDIA.IMPORT.ERROR.' + mediaFailedTooltip | translate"
          src="/assets/images/badges/badge-failed-red.svg">
      </div>
    </div>
    <div class="media-tile__content__badges">
      <img *ngIf="showBadges && showValidityBadge" [matTooltip]="validityBadgeTooltip" [matTooltipShowDelay]="500"
        [src]="validityBadgeSrc">
      <div *ngIf="media && media.externalMediaSource">
        <ng-container [ngSwitch]="media.externalMediaSource">
          <img class="media-tile__content__badges_icon" *ngSwitchCase="ExternalMediaSource.PosterMyWall"
            [matTooltip]="'MEDIA.EXTERNAL_SOURCE.POSTER_MY_WALL.TOOLTIP' | translate"
            src="/assets/images/badges/badge-postermywall.jpeg">
        </ng-container>
      </div>
    </div>
    <div *ngIf="showIncludedInScenarioIcon" class="media-tile__content__bottom">
      <div class="media-tile__content__bottom__badge">
        <img [matTooltip]="'MEDIA.INCLUDED_IN_SCENARIO' | translate" [matTooltipShowDelay]="500"
          src="/assets/images/badges/badge-scenario.svg">
      </div>
    </div>
    <mat-spinner *ngIf="!media || media.status == 'Processing'; else thumbnail" [diameter]="24"></mat-spinner>
    <ng-template #thumbnail>
      <img [src]="thumbnailUrl">
    </ng-template>
  </div>
  <div *ngIf="showName && media" class="media-tile__footer">
    <span>{{ media.name }}</span>
  </div>
</div>