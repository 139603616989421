import {HttpClient, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {App, HttpShowError, HumecloudApps, InvocationResult} from "hcl-lib"
import {environment} from 'projects/hcl-portal/src/environments/environment'
import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'

interface AppModel {
  color: string;
  icon: string;
  logo: string;}

const _apps: {[key: string]: AppModel} = {
  [HumecloudApps.HUMENU]: {
    color: "yellow",
    icon: "/assets/images/apps/icon-humenu.svg",
    logo: "/assets/images/apps/logo-humenu.svg"
  },
  [HumecloudApps.HUMEFAN]: {
    color: "green",
    icon: "/assets/images/apps/icon-humefan.svg",
    logo: "/assets/images/apps/logo-humefan.svg"
  },
  [HumecloudApps.HUMEDIRECTORY]: {
    color: "green",
    icon: "/assets/images/apps/icon-humedirectory.svg",
    logo: "/assets/images/apps/logo-humedirectory.svg"
  },
  [HumecloudApps.SCREENLAB]: {
    color: "blue",
    icon: "/assets/images/apps/icon-screenlab.svg",
    logo: "/assets/images/apps/logo-screenlab.svg"
  },
  [HumecloudApps.HUMEHEALTH]: {
    color: "#02E2B6",
    icon: "/assets/images/apps/icon-humeservices.svg",
    logo: "/assets/images/apps/logo-humeservices.svg"
  },
  [HumecloudApps.HUMEORDER]: {
    color: "#00C0E0",
    icon: "/assets/images/apps/icon-humeorder.svg",
    logo: "/assets/images/apps/logo-humeorder.svg"
  },
  [HumecloudApps.HUMETIPS]: {
    color: "orange",
    icon: "/assets/images/apps/icon-humetips.svg",
    logo: "/assets/images/apps/logo-humetips.svg"
  },
  [HumecloudApps.HUMECHARITY]: {
    color: "orange",
    icon: "/assets/images/apps/icon-humecharity.svg",
    logo: "/assets/images/apps/logo-humecharity.svg"
  },
  [HumecloudApps.HUMEHOSPITALITY]: {
    color: "purple",
    icon: "/assets/images/icon-humecloud.svg",
    logo: "/assets/images/icon-humecloud.svg"
  },
  [HumecloudApps.HUMEIPTV]: {
    color: "red",
    icon: "/assets/images/apps/icon-humeiptv.png",
    logo: "/assets/images/apps/logo-humeiptv.svg"
  },
  [HumecloudApps.HUMECORE]: {
    color: "dark-red",
    icon: "/assets/images/icon-humecloud.svg",
    logo: "/assets/images/icon-humecloud.svg"
  }
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private httpClient: HttpClient
  ) {
    // do nothing
  }

  @HttpShowError()
  getAppsWithPaging(pageNumber: number, pageSize: number): Observable<InvocationResult> {
    const queryParams = new HttpParams()
      .set('pageSize', pageSize.toString())
      .set('pageNumber', pageNumber.toString());
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/apps', {params: queryParams}).pipe(
      map((res: any) => {
        return (res as InvocationResult)
      })
    )
  }

  @HttpShowError()
  getApps(): Observable<App[]> {
    return this.httpClient.get(environment.cmnAppApiBaseUrl + '/apps').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  getAppIcon(appName: string): string {
    return appName in _apps ? _apps[appName].icon : ""
  }

  getAppLogo(appName: string): string {
    return appName in _apps ? _apps[appName].logo : ""
  }

  getAppColor(appName: string): string {
    return appName in _apps ? _apps[appName].color : ""
  }

  getAppDisplayNameHtml(appName: string) {
    switch (appName) {
      case HumecloudApps.HUMEDIRECTORY:
        return 'HUME<span class="hc-humedirectory-colored-text">DIRECTORY</span>'
      case HumecloudApps.HUMENU:
        return 'HU<span class="hc-humenu-colored-text">MENU</span>'
      case HumecloudApps.HUMEFAN:
        return 'HUME<span class="hc-humefan-colored-text">FAN</span>'
      case HumecloudApps.HUMEHEALTH:
        return 'HUME<span class="hc-humeservices-colored-text">SERVICES</span>'
      case HumecloudApps.HUMEORDER:
        return 'HUME<span class="hc-humeorder-colored-text">ORDER</span>'
      case HumecloudApps.SCREENLAB:
        return '<span class="hc-screenlab-colored-text">SCREEN</span>LAB'
      case HumecloudApps.HUMETIPS:
        return 'HUME<span class="hc-humetips-colored-text">TIPS</span>'
      case HumecloudApps.HUMECHARITY:
        return 'HUME<span class="hc-humecharity-colored-text">CHARITY</span>'
      case HumecloudApps.HUMEHOSPITALITY:
        return 'HUME<span class="hc-humehospitality-colored-text">HOSPITALITY</span>'
      case HumecloudApps.HUMEIPTV:
        return 'HUME<span class="hc-humeiptv-colored-text">IPTV</span>'
      case HumecloudApps.HUMECORE:
        return 'HUME<span class="hc-humecore-colored-text">CORE</span>'
      default:
        return appName
    }
  }
}
