import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Customer, ScopeService } from "hcl-lib";
import { Subscription } from "rxjs";
import { Media } from "../../../interfaces/media";
import { MediaService } from "../../../services/media/media.service";
import { NgIf } from "@angular/common";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { CustomerRecentSearchSelectComponent } from "../../../Layout/Components/widget/customer-recent-search-select/customer-recent-search-select.component";
import { MatButtonModule } from "@angular/material/button";

interface DuplicateMediaData {
  media: Media
}

@Component({
  selector: 'app-media-duplicate-dialog',
  templateUrl: './media-duplicate-dialog.component.html',
  styleUrls: ['./media-duplicate-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatDialogModule, MatButtonModule, TranslateModule, CustomerRecentSearchSelectComponent]
})
export class MediaDuplicateDialogComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription()

  duplicateForm!: UntypedFormGroup
  media!: Media
  selectedCustomer!: Customer

  constructor(
    public dialogRef: MatDialogRef<MediaDuplicateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DuplicateMediaData,
    private formBuilder: UntypedFormBuilder,
    private mediaService: MediaService,
    private scopeService: ScopeService
  ) { }

  ngOnInit(): void {
    this.media = this.data.media
    this.duplicateForm = this.formBuilder.group({
      name: [this.media.name, Validators.required]
    })
    this.initializeSelectedCustomer()
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  initializeSelectedCustomer(): void {
    this.subscriptions.add(
      this.scopeService.getScopeCustomer().subscribe(customer => {
        if (!this.selectedCustomer) {
          this.selectedCustomer = customer as Customer
        }
      })
    )
  }

  doDuplicate(): void {
    const name = this.duplicateForm.value.name
    this.mediaService.duplicateMedia(this.media.id, this.selectedCustomer.id as string, name).subscribe(_ => {
      this.dialogRef.close()
    })
  }

  onSelectedCustomer(customer: Customer): void {
    this.selectedCustomer = customer
  }
}
