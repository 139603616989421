import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { InvocationResult, QueryScope } from 'hcl-lib';
import { environment } from "projects/hcl-portal/src/environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Directory } from "../../interfaces/directory";
import { DirectoryDto } from "../../interfaces/dto/directory-dto";
import { MediaDomainFilterEnum } from '../../interfaces/media-domain-filter-enum';

@Injectable({
  providedIn: 'root'
})
export class DirectoryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  directoriesToTree(directories: Directory[]): any[] {
    const hashTable = Object.create(null)
    directories.forEach(directory => hashTable[directory.id as string] = { ...directory, children: [] })
    const dataTree: any[] = []
    directories.forEach(directory => {
      if (directory.parentId) {
        hashTable[directory.parentId]?.children.push(hashTable[directory.id as string])
      } else {
        dataTree.push(hashTable[directory.id as string])
      }
    })
    directories.forEach(directory => {
      if (!directory.parentId && hashTable[directory.id as string].children.length > 1) {
        hashTable[directory.id as string].children.sort((a: Directory, b: Directory) => (a.name as string).localeCompare(b.name as string))
      }
    })
    return dataTree
  }

  getDirectories(
    queryScope?: QueryScope,
    mediaDomainFilter?: MediaDomainFilterEnum,
    domains?: string[],
    isPublic?: boolean
  ): Observable<Directory[]> {
    let url = `${environment.mediaApiBaseUrl}/directories`
    let queryParams = new HttpParams()

    if (queryScope) {
      queryParams = queryParams.append("queryScope", queryScope)
    }

    if (mediaDomainFilter) {
      queryParams = queryParams.append("mediaDomainFilter", mediaDomainFilter)
    }

    if (domains) {
      domains.forEach(domain => {
        queryParams = queryParams.append("domains", domain)
      })
    }

    if (isPublic) {
      queryParams = queryParams.append("public", isPublic)
      url += "/public"
    }
    return this.httpClient.get(url, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  createDirectory(directory: Directory): Observable<Directory> {
    const url = `${environment.mediaApiBaseUrl}/directories`
    const directoryDto: DirectoryDto = {
      name: directory.name as string,
      parentId: directory.parentId
    }
    return this.httpClient.post(url, directoryDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  updateDirectory(id: string, directory: Directory): Observable<Directory> {
    const url = `${environment.mediaApiBaseUrl}/directories/${id}`
    const directoryDto: DirectoryDto = {
      name: directory.name as string,
      parentId: directory.parentId
    }
    return this.httpClient.put(url, directoryDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  deleteDirectory(id: string): Observable<any> {
    const url = `${environment.mediaApiBaseUrl}/directories/${id}`
    return this.httpClient.delete(url)
  }
}
