import { NgFor, NgIf } from "@angular/common"
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms"
import { MatDividerModule } from "@angular/material/divider"
import { MatInputModule } from "@angular/material/input"
import { MatSelect } from "@angular/material/select"
import { MatSelectModule } from "@angular/material/select"

@Component({
  selector: "searchable-select",
  templateUrl: "./searchable-select.component.html",
  styleUrls: ["./searchable-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SearchableSelectComponent
    }
  ],
  standalone: true,
  imports: [NgFor, NgIf, MatInputModule, MatSelectModule, MatDividerModule]
})
export class SearchableSelectComponent implements OnInit, ControlValueAccessor {

  @Input() data: { id?: string, name: string, selected: boolean }[] = []
  @Input() multipleSelect = false
  @Input() showSelectArrow = false
  @Input() placeholder = ''
  @Input() stickyPlaceholder = false
  @Input() panelWidth: string | number | null = null

  @Output() selectionChanged: EventEmitter<string[]> = new EventEmitter()

  @ViewChild("searchField") searchField!: ElementRef

  @ViewChild(MatSelect, { static: true }) matSelect!: MatSelect

  filteredData: { id?: string, name: string }[] = []
  selectedData!: { id?: string, name: string } | { id?: string, name: string }[]
  touched = false
  disabled = false

  onChange = (value: any) => { }
  onTouched = () => { }

  ngOnInit(): void {
    this.matSelect.multiple = this.multipleSelect
    this.matSelect.placeholder = this.placeholder
    this.initSelectedData()
  }

  initSelectedData() {
    if (this.data.length > 0) {
      if (this.multipleSelect) {
        this.selectedData = this.data.filter(current => current.selected)
      } else {
        this.selectedData = this.data.find(current => current.selected) ?? []
      }
    }
    this.matSelect.disabled = this.data.length < 1 || this.disabled
    this.filteredData = this.data
  }

  writeValue(value: { id: string, name: string, selected: boolean }[]): void {
    this.data = value
    this.initSelectedData()
    this.onChange(this.data.filter(data => data.selected).map(data => data.id))
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange
    this.onChange(this.data.filter(data => data.selected).map(data => data.id))
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled
    this.matSelect.disabled = disabled
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  ignoreSpaceKey($event: KeyboardEvent) {
    if ($event.key === " ") {
      this.searchField.nativeElement.value += $event.key
      this.onSearch()
      this.stopPropagation($event)
    }
  }

  onSearch() {
    const filter = this.searchField.nativeElement.value
    this.filteredData = filter ? this.data.filter(current => (current.name.toLocaleLowerCase().includes(filter.toLowerCase()))) : this.data
    if (Array.isArray(this.selectedData)) {
      this.selectedData = this.filteredData.filter(filteredEntry => this.data.find(entry => entry.id === filteredEntry.id)?.selected)
    }
  }

  onOpen($event: boolean) {
    const open = $event
    this.markAsTouched()
    if (open) {
      this.searchField.nativeElement.focus()
    } else {
      this.searchField.nativeElement.value = ""
      this.onSearch()
    }
  }

  onSelectionChange(selectedData: { id?: string; name: string; selected?: boolean } | { id?: string; name: string; selected?: boolean }[]) {
    let selectedIds: string[] = []
    if (Array.isArray(selectedData)) {
      this.selectedData = selectedData
        .concat(this.data
          .filter(entry => entry.selected === true && !this.filteredData.find(filtered => filtered.id === entry.id))
        )
      selectedIds = this.selectedData.map(selected => selected.id as string)
      this.data = this.data.map(entry => {
        return { id: entry.id, name: entry.name, selected: selectedIds.includes(entry.id as string) }
      })
    } else {
      selectedIds = [selectedData.id as string]
      this.selectedData = selectedData
    }
    this.onChange(selectedIds)
    this.selectionChanged.emit(selectedIds)
  }

  stopPropagation($event: any) {
    $event.preventDefault()
    $event.stopPropagation()
  }
}
