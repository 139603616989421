import { DayOfWeek } from "hcl-lib";
import { WeekDay } from "@angular/common";

export class TimeSlotUtil {

  constructor() {
    // do nothing
  }

  static floorToNearest(amount: number, precision: number): number {
    return Math.floor(amount / precision) * precision
  }

  static ceilToNearest(amount: number, precision: number): number {
    return Math.ceil(amount / precision) * precision
  }

  static getDayOfWeekOfDate(date: Date): DayOfWeek | undefined {
    return this.getDayOfWeek(date.getDay())
  }

  static getDayOfWeek(day: number): DayOfWeek | undefined {
    switch (day) {
      case 0: return DayOfWeek.SUNDAY
      case 1: return DayOfWeek.MONDAY
      case 2: return DayOfWeek.TUESDAY
      case 3: return DayOfWeek.WEDNESDAY
      case 4: return DayOfWeek.THURSDAY
      case 5: return DayOfWeek.FRIDAY
      case 6: return DayOfWeek.SATURDAY
    }
    return
  }

  static getDayOfWeekMoment(dayOfWeek: DayOfWeek): number {
    switch (dayOfWeek) {
      case DayOfWeek.SUNDAY: return WeekDay.Sunday
      case DayOfWeek.MONDAY: return WeekDay.Monday
      case DayOfWeek.TUESDAY: return WeekDay.Tuesday
      case DayOfWeek.WEDNESDAY: return WeekDay.Wednesday
      case DayOfWeek.THURSDAY: return WeekDay.Thursday
      case DayOfWeek.FRIDAY: return WeekDay.Friday
      case DayOfWeek.SATURDAY: return WeekDay.Saturday
    }
  }

  static getDayOfWeekNumber(dayOfWeek: DayOfWeek): number {
    switch (dayOfWeek) {
      case DayOfWeek.MONDAY: return 0
      case DayOfWeek.TUESDAY: return 1
      case DayOfWeek.WEDNESDAY: return 2
      case DayOfWeek.THURSDAY: return 3
      case DayOfWeek.FRIDAY: return 4
      case DayOfWeek.SATURDAY: return 5
      case DayOfWeek.SUNDAY: return 6
    }
  }

  /*
   * Retrieve the date of the current week DayOfWeek (with monday as start of a week)
   * ex: We are Tuesday 19 of January 2021
   * DayOfWeek.Thursday will return the date of Thursday 21 of January
   */
  static getMonthDateOfDayOfWeek(dayOfWeek: DayOfWeek): Date {
    const today = new Date()
    return this.getDateOfDayOfWeekFromDate(dayOfWeek, today)
  }

  /*
   * Retrieve the date of the DayOfWeek from the provided date week
   */
  static getDateOfDayOfWeekFromDate(dayOfWeek: DayOfWeek, date: Date): Date {
    const dateDay = date.getDay()
    const startOfWeek = date.getDate() - (dateDay == 0 ? 6 : dateDay - 1)
    const dayOfWeekNumber = TimeSlotUtil.getDayOfWeekNumber(dayOfWeek)
    return new Date(date.setDate(startOfWeek + dayOfWeekNumber))
  }

  static getScheduleEventDate(dayOfWeek: DayOfWeek, time: string): Date {
    const date = TimeSlotUtil.getMonthDateOfDayOfWeek(dayOfWeek)
    const timeSplit = time.split(':')
    date.setHours(parseInt(timeSplit[0]), parseInt(timeSplit[1]), 0, 0)
    return date
  }

  static getDateAtTime(date: Date, time: string): Date {
    const newDate = new Date(date)
    const timeSplit = time.split(':')
    newDate.setHours(parseInt(timeSplit[0]), parseInt(timeSplit[1]), 0, 0)
    return newDate
  }

  static getTimeWithoutSeconds(time: string): string {
    const timeSplit = time.split(':')
    return timeSplit.length > 2 ? timeSplit[0] + ":" + timeSplit[1] : ""
  }

  static getTimeWithoutSecondsFromDate(date: Date): string {
    date = new Date(date)
    return date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0')
  }

  static getTimeWithSecondsFromTimePickerFormat(time: string): string {
    const isAfternoon = time.search(/pm/gi) != -1
    const timeWithoutMeridiem = time.split(" ")[0]
    const timeSplit = timeWithoutMeridiem.split(':')
    const hours = isAfternoon ? parseInt(timeSplit[0]) + 12 : parseInt(timeSplit[0])
    const hoursStr = hours < 10 ? "0" + hours : hours
    return timeSplit.length >= 2 ? hoursStr + ":" + timeSplit[1] + ":00" : ""
  }

  static formatToLocalDateTime(date: Date): Date {
    date = new Date(date)
    const timezoneOffset = date.getTimezoneOffset() * 60000
    return new Date(date.getTime() - timezoneOffset)
  }
}
