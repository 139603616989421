import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CmnInputAutoFocusDirective } from 'hcl-lib';
import { Subject, Subscription, asapScheduler } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-recent-search-select',
  templateUrl: 'recent-search-select.component.html',
  styleUrls: ['recent-search-select.component.scss'],
  standalone: true,
  imports: [CmnInputAutoFocusDirective, NgFor, NgIf, FormsModule, MatProgressSpinnerModule, MatListModule, MatButtonModule, MatIconModule, MatTooltipModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatDividerModule, PortalModule, TranslateModule]
})
export class RecentSearchSelectComponent implements OnInit, OnChanges, OnDestroy {

  @Input() placeholder: string = "Select one choice"
  @Input() search: string = ""
  @Input() selected?: Choice
  @Input() recentChoices: Choice[] = []
  @Input() headerClass: string = ""
  @Input() foundChoices: Choice[] = []

  @Output() searchChange = new EventEmitter<string>()
  @Output() selectionChange = new EventEmitter<Choice>()

  @ViewChild('panel') private panel!: TemplatePortal
  @ViewChild('origin', { read: ElementRef }) private origin!: ElementRef

  searchStream = new Subject<any>()
  subscription: Subscription = new Subscription()
  debounceTime = 400
  isDebouncing = false
  isLoadingResults = false
  selectedPrimary!: string;
  selectedSecondary?: string;

  private overlayRef!: OverlayRef

  constructor(
    public overlay: Overlay
  ) {
    // do nothing
  }

  ngOnInit(): void {
    this.initSearch()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['foundChoices'] && changes['foundChoices'].currentValue) {
      this.isLoadingResults = false
    }
    if (changes['selected'] && changes['selected'].currentValue) {
      this.initSelected()
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  openPanel() {
    let strategy = this.overlay.position()
      .flexibleConnectedTo(this.origin)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      }])

    let config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: strategy,
      width: this.origin.nativeElement.getBoundingClientRect().width,
    })

    this.overlayRef = this.overlay.create(config)
    this.overlayRef.attach(this.panel)
    this.overlayRef.backdropClick().subscribe(() => this.overlayRef.detach())
  }

  onSelect($event: MatSelectionListChange): void {
    this.overlayRef.detach()
    this.clear()
    this.selectionChange.emit($event.options[0].value)
  }

  onSearch(): void {
    this.isDebouncing = true
    this.isLoadingResults = true
    this.searchStream.next(this.search)
  }

  clear(): void {
    this.search = ''
  }

  initSearch(): void {
    this.subscription.add(
      this.searchStream.pipe(
        debounceTime(this.debounceTime, asapScheduler),
        distinctUntilChanged()
      ).subscribe(_ => {
        this.isDebouncing = false
        this.searchChange.emit(this.search)
      }
      )
    )
  }

  initSelected() {
    if (this.selected) {
      if (this.selected.displayName && this.selected.displayName !== this.selected.name) {
        this.selectedPrimary = this.selected.displayName;
        this.selectedSecondary = this.selected.name;
      } else {
        this.selectedPrimary = this.selected.name;
        this.selectedSecondary = undefined;
      }
    } else {
      this.selectedPrimary = this.placeholder;
      this.selectedSecondary = undefined;
    }
  }
}

export interface Choice {
  id: string,
  name: string,
  displayName?: string
}
