import {CdkDragStart, DragDropModule} from "@angular/cdk/drag-drop"
import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from "@angular/core"
import {ReactiveFormsModule, UntypedFormBuilder} from "@angular/forms"
import {MatDialog, MatDialogConfig} from "@angular/material/dialog"
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator"
import {MatSort, Sort} from "@angular/material/sort"
import {MatTableDataSource} from "@angular/material/table"
import {Router} from "@angular/router"
import {TranslateModule, TranslateService} from "@ngx-translate/core"
import {
  CmnIfPermDirective,
  CmnIfRoleDirective,
  Customer,
  Domain,
  QueryScope,
  ScopeService,
  SortSelectComponent,
  TagService,
  TargetType
} from "hcl-lib"
import {Media, MediaOrientation, MediaType, MediaTypePerm} from "projects/hcl-portal/src/app/common/interfaces/media"
import {MediaService} from "projects/hcl-portal/src/app/common/services/media/media.service"
import {SlideType} from "projects/hcl-portal/src/app/screenlab/interfaces/slide"
import {
  DroppableSlideData
} from "projects/hcl-portal/src/app/screenlab/pages/scenario/unique-scenario/unique-scenario.component"
import {asapScheduler, forkJoin, from, Observable, of, Subject, Subscription, zip} from "rxjs"
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, tap, toArray} from "rxjs/operators"
import {MediaCreateDialogComponent} from "../../../../pages/media/media-create-dialog/media-create-dialog.component"
import {MediaUpdateDialogComponent} from "../../../../pages/media/media-update-dialog/media-update-dialog.component"
import {
  InheritableTemplateDialogComponent
} from "../template/inheritable-template-dialog/inheritable-template-dialog.component"
import {TemplateCreateDialogComponent} from "../template/template-create-dialog/template-create-dialog.component"
import {Directory} from "../../../../interfaces/directory"
import {DirectoryService} from "../../../../services/directory/directory.service"
import {NestedTreeControl} from "@angular/cdk/tree"
import {DirectoryComponent, DirectoryMode} from "../directory/directory.component"
import {MediaListWidgetType} from "./media-list-widget-type"
import {BreakpointObserver} from "@angular/cdk/layout"
import {MediaDomainFilterEnum} from "../../../../interfaces/media-domain-filter-enum"
import {NgClass, NgFor, NgIf, NgTemplateOutlet} from "@angular/common"
import {MatFormFieldModule} from "@angular/material/form-field"
import {MatInputModule} from "@angular/material/input"
import {MatButtonModule} from "@angular/material/button"
import {MatIconModule} from "@angular/material/icon"
import {MatTreeModule} from "@angular/material/tree"
import {MediaTileComponent} from "../media-tile/media-tile.component"
import {SearchableSelectComponent} from "../searchable-select/searchable-select.component"
import {TemplateService} from "../../../../services/template/template.service";
import {Template, TemplateType} from "../../../../interfaces/template";
import {MatSlideToggle} from "@angular/material/slide-toggle"
import {
  MatDatepicker,
  MatDatepickerToggle,
  MatDatepickerToggleIcon,
  MatDateRangeInput,
  MatDateRangePicker, MatEndDate, MatStartDate
} from "@angular/material/datepicker";

@Component({
  selector: "app-media-list-widget",
  templateUrl: "./media-list-widget.component.html",
  styleUrls: ["./media-list-widget.component.scss"],
  standalone: true,
  imports: [NgFor, NgIf, NgClass, NgTemplateOutlet, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatIconModule, MatPaginatorModule, MatTreeModule, DragDropModule, TranslateModule, CmnIfPermDirective, SearchableSelectComponent, MediaTileComponent, SortSelectComponent, DirectoryComponent, MatSlideToggle, CmnIfRoleDirective, MatDateRangeInput, MatDateRangePicker, MatDatepickerToggle, MatDatepickerToggleIcon, MatEndDate, MatStartDate]
})
export class MediaListWidget implements OnInit, AfterViewInit, OnDestroy {
  subscriptions: Subscription = new Subscription()
  @ViewChild('picker') datepicker: MatDatepicker<Date> | undefined;


  mediaFilterForm = this.formBuilder.group({
    search: [""],
    mediaTypes: [[]],
    orientations: [
      Object.keys(MediaOrientation).map(orientation => ({
        id: orientation,
        name: this.translateService.instant("MEDIA.ORIENTATION." + orientation),
        selected: false
      }))
    ],
    domains: [[]],
    tags: [[]],
    system: [false],
    validityStart: [],
    validityEnd: []
  })

  selectedScope: QueryScope = QueryScope.CUSTOMER
  domains: string[] = []
  isPublic = false

  mediaDomainFilter?: MediaDomainFilterEnum
  mediaTypePerm: MediaType[] = []
  mediaOrientations: MediaOrientation[] = [
    MediaOrientation.LANDSCAPE,
    MediaOrientation.PORTRAIT,
    MediaOrientation.SQUARE,
    MediaOrientation.UNDEFINED
  ]

  searchKeyUp = new Subject<string>()

  mediaDataSource = new MatTableDataSource<DroppableSlideData>()
  columnsToDisplay = ["media", "type", "name", "actions"]

  selectedMedia!: Media

  dragging!: boolean
  dragDisabled!: boolean
  isMobilePhone = false
  directoryFilter = false
  showSelectDomains = true
  showFormMedia = false

  customer!: Customer

  // Directories
  treeControl = new NestedTreeControl<Directory>(node => node.children)
  directoryMode: DirectoryMode = DirectoryMode.ALL
  subDirectories!: Directory[]
  directoryDataSource = new MatTableDataSource<Directory>()
  currentDirectory: Directory = { id: "", name: "" }
  directoryPath = ""

  _mediaIdsIncludedInCurrentScenario: string[] = []

  readonly subtitleMediaTypesToDisplay = [
    MediaType.Image,
    MediaType.Video,
    MediaType.Template
  ]

  @Input() title!: string
  @Input() subtitle!: string
  @Input() set mediaIdsIncludedInCurrentScenario(mediaIds: string[]) {
    this._mediaIdsIncludedInCurrentScenario = mediaIds
    this.mediaDataSource.data.forEach(droppableSlideData => {
      droppableSlideData.includedInScenario = this.isMediaIncludedInCurrentScenario((droppableSlideData.data as Media).id)
    })
  }
  @Input() mediaListWidgetType: MediaListWidgetType = MediaListWidgetType.MY_MEDIAS
  @Input() allowedMediaTypes: MediaType[] = [
    MediaType.Art,
    MediaType.Audio,
    MediaType.Image,
    MediaType.Video,
    MediaType.Template,
    MediaType.Dynamic_Template
  ]
  @Input() restrictToAppIds: string[] = []
  @Input() selectMode = false
  @Input() showMediaCreateButton = true
  @Input() showTemplateCreateButton = true

  @Output() mediaSelected: EventEmitter<Media> = new EventEmitter()
  @Output() mediaImported: EventEmitter<Media> = new EventEmitter()

  @ViewChild("paginator", { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort
  @ViewChild(DirectoryComponent) directoryComponent!: DirectoryComponent

  availableSorts: Sort[] = [
    { active: "createdAt", direction: "desc" },
    { active: "modifiedAt", direction: "desc" },
    { active: "name", direction: "asc" },
  ]
  selectedSort = this.availableSorts[0]

  readonly MediaListWidgetType = MediaListWidgetType
  readonly DirectoryMode = DirectoryMode

  constructor(
    private directoryService: DirectoryService,
    private mediaService: MediaService,
    private matDialog: MatDialog,
    private translateService: TranslateService,
    private tagService: TagService,
    private scopeService: ScopeService,
    private formBuilder: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    private templateService: TemplateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.mediaFilterForm.get("system")?.valueChanges.subscribe(_ => {
        this.resetPagination()
        this.handlePage()
      })
    )

    this.initMobilePhone()
    this.initConfigByMediaListWidgetType()
    this.initSearchKeyUp()
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.scopeService.getScopeCustomer().pipe(
        tap(customerIt => { this.customer = customerIt as Customer }),
        mergeMap(_ => forkJoin(
          [this.initMediaTypes(), this.initDomains(), this.initTags()]
        ))
      ).subscribe(([types, domains, tags]) => {
        this.mediaFilterForm.patchValue({
          mediaTypes: types,
          orientations: this.initMediaOrientations(),
          domains,
          tags,
        })
        this.mediaDataSource.sort = this.sort
        this.retrieveMedias()
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  openDatepicker() {
    this.datepicker?.open();
  }

  initMobilePhone(): void {
    this.isMobilePhone = this.breakpointObserver.isMatched("(max-width: 800px)")
    this.dragDisabled = this.isMobilePhone || this.selectMode
  }

  initTags(): Observable<{ id?: string, name: string, selected: boolean }[]> {
    return this.tagService.getAvailableTags([TargetType.MEDIA]).pipe(
      map(tags => tags.map(tag => ({ id: tag.id, name: tag.name, selected: false }))),
    )
  }

  initDomains(): Observable<{ id: string, name: string, selected: boolean }[]> {
    return this.scopeService.hasPermission("scr:media:filter:domains").pipe(
      map(perm => {
        if (!perm) {
          return []
        }
        if (this.mediaListWidgetType === MediaListWidgetType.PUBLIC_MEDIAS) {
          return []
        }
        if (this.mediaListWidgetType === MediaListWidgetType.ACTIVITY_MEDIAS) {
          return [this.customer.domain]
        }
        return Object.keys(Domain)
      }),
      map(domains => {
        this.domains = domains
        return domains.map(domainIt => ({
          id: Domain[domainIt as keyof typeof Domain],
          name: this.translateService.instant("DOMAIN." + domainIt),
          selected: (this.mediaListWidgetType === MediaListWidgetType.ACTIVITY_MEDIAS) && (this.customer.domain === Domain[domainIt as keyof typeof Domain])
        }))
      })
    )
  }

  initMediaTypes(): Observable<{ id: string, name: string, selected: boolean }[]> {
    return from(Object.keys(MediaType)).pipe(
      filter(type => this.allowedMediaTypes.includes(MediaType[type as keyof typeof MediaType])),
      filter(type => type != MediaType.Art && type != MediaType.Dynamic_Template), // remove Art and Dynamic Tamplate from MediaTypes for time being
      mergeMap(type => zip(
        of(type),
        this.scopeService.hasPermission(MediaTypePerm[type as keyof typeof MediaTypePerm])
      )),
      filter(([_, perm]) => perm),
      map(([type, _]) => type),
      toArray(),
      tap(types => {
        this.mediaTypePerm = types.map(type => MediaType[type as keyof typeof MediaType])
      }),
      map(types => types.map(type => ({
        id: type,
        name: this.translateService.instant("MEDIA.TYPE." + type),
        selected: false
      })))
    )
  }

  initMediaOrientations(): { id: string, name: string, selected: boolean }[] {
    return Object.keys(MediaOrientation).map(orientation => ({
      id: orientation,
      name: this.translateService.instant("MEDIA.ORIENTATION." + orientation),
      selected: false
    }))
  }

  initSearchKeyUp(): void {
    this.subscriptions.add(
      this.searchKeyUp.pipe(
        debounceTime(800, asapScheduler),
        distinctUntilChanged(),
      ).subscribe(_ => {
        this.resetPagination()
        this.handlePage()
      })
    )
  }

  initConfigByMediaListWidgetType(): void {
    switch (this.mediaListWidgetType) {
      case MediaListWidgetType.MY_MEDIAS:
        this.selectedScope = QueryScope.CUSTOMER
        this.mediaDomainFilter = MediaDomainFilterEnum.INCLUDE_WITHOUT_DOMAIN
        this.isPublic = false
        this.showSelectDomains = true
        break
      case MediaListWidgetType.PUBLIC_MEDIAS:
        this.selectedScope = QueryScope.ANCESTORS
        this.mediaDomainFilter = MediaDomainFilterEnum.ONLY_WITHOUT_DOMAIN
        this.isPublic = true
        this.showSelectDomains = false
        break
      case MediaListWidgetType.ACTIVITY_MEDIAS:
        this.selectedScope = QueryScope.ANCESTORS
        this.mediaDomainFilter = MediaDomainFilterEnum.EXCLUDE_WITHOUT_DOMAIN
        this.isPublic = true
        this.showSelectDomains = false
        break
    }
  }

  handlePage(): void {
    this.retrieveMedias()
  }

  resetPagination() {
    this.paginator.firstPage()
  }

  retrieveMedias(): void {
    this.subscriptions.add(
      this.mediaService.getMediasWithPaging(
        this.paginator.pageIndex,
        this.paginator.pageSize,
        undefined,
        this.formatSearchableData(this.mediaFilterForm.value.tags),
        this.formatTypesMedia(this.mediaFilterForm.value.mediaTypes),
        this.directoryFilter,
        this.currentDirectory.id,
        this.mediaFilterForm.value.validityStart,
        this.mediaFilterForm.value.validityEnd,
        this.selectedScope,
        undefined,
        this.mediaFilterForm.value.search,
        this.formatSearchableData(this.mediaFilterForm.value.domains),
        this.selectedSort,
        this.restrictToAppIds,
        this.mediaDomainFilter,
        this.formatOrientations(this.mediaFilterForm.value.orientations),
        this.mediaFilterForm.get("system")?.value
      ).subscribe(invocationResult => {
        this.paginator.length = invocationResult.pagination?.count as number
        this.mediaDataSource.data = invocationResult.data.filter((media: Media) => {
          return this.mediaDomainFilter ? true : !(media.visibleForDomains?.length === 0)
        }).map((media: Media) => {
          return {
            slideType: SlideType.MEDIA,
            includedInScenario: this.isMediaIncludedInCurrentScenario(media.id),
            data: media
          }
        })
      })
    )
  }

  formatTypesMedia(values: string[]): MediaType[] {
    if (values.length === 0) {
      return this.mediaTypePerm
    } else {
      return this.mediaTypePerm.filter(type => values.find(v => v === type))
    }
  }

  formatOrientations(values: string[]): MediaOrientation[] {
    return this.mediaOrientations.filter(type => values.find(v => v === type))
  }

  formatSearchableData(values: any[]): string[] {
    return values.map(value => {
      if (typeof value === "string" || value instanceof String) {
        return value
      } else if (value.selected) {
        return value.id
      }
    }).filter(value => value !== undefined)
  }

  openMediaCreateDialog(): void {
    const config = new MatDialogConfig()
    config.data = {
      directoryId: this.currentDirectory.id,
      managedMediaTypes: this.mediaTypePerm
    }
    const mediaCreateDialogRef = this.matDialog.open(MediaCreateDialogComponent, config)
    this.subscriptions.add(
      mediaCreateDialogRef.afterClosed().subscribe(createdMedia => {
        if (createdMedia) {
          this.mediaImported.emit(createdMedia)
          this.handlePage()
        }
      })
    )
  }

  onMediaClick(media: Media): void {
    if (this.selectMode) {
      this.selectedMedia = media
      this.mediaSelected.emit(media)
    } else {
      this.openMediaUpdateDialog(media)
    }
  }

  openMediaUpdateDialog(media: Media): void {
    const config = new MatDialogConfig()
    config.data = { media }
    const mediaUpdateDialogRef = this.matDialog.open(MediaUpdateDialogComponent, config)
    this.subscriptions.add(
      mediaUpdateDialogRef.afterClosed().subscribe(_ => {
        this.handlePage()
      })
    )
  }

  onFilterSelectionChanged(): void {
    this.resetPagination()
    this.handlePage()
  }

  openTemplateCreatePage() {
    this.subscriptions.add(zip(
      this.scopeService.hasPermission("scr:media:create:template"),
      this.scopeService.hasPermission("scr:media:create:inherited-template"),
      this.scopeService.hasPermission("scr:template:create:full-inherited"))
      .subscribe(([canCreateTemplate, canCreateInheritedTemplate, canCreateFullInherited]) => {
        if (canCreateInheritedTemplate && canCreateTemplate) {
          this.matDialog.open(TemplateCreateDialogComponent)
        } else if (canCreateInheritedTemplate || canCreateFullInherited) {
          this.chooseInheritingTemplate(canCreateFullInherited)
        } else if (canCreateTemplate) {
          this.router.navigate(["screenlab", "templates", "create"])
        }
      }))
  }

  onDirectoryFilter(event: boolean) {
    if (!event) {
      this.subDirectories = []
    }
    this.directoryFilter = event
    this.directoryMode = (event) ? DirectoryMode.TREE : DirectoryMode.ALL
    this.resetPagination()
    this.handlePage()
  }

  onShareTreeControl(event: {
    treeControl: NestedTreeControl<Directory>,
    directoryMode: DirectoryMode,
    directoryDataSource: MatTableDataSource<Directory>
  }): void {
    if (event.directoryMode === DirectoryMode.TREE) {
      this.treeControl = event.treeControl
      this.directoryMode = event.directoryMode
      this.directoryDataSource = event.directoryDataSource
    }
  }

  shownDirectory(node: Directory): boolean {
    let result = false
    /* Hide home directory when the home is selected */
    if (!this.currentDirectory.name && !node.name) {
      return false
    }
    if ((this.currentDirectory.parentId === node.id) || (!node.name && !this.currentDirectory.parentId)) {
      return true
    }
    this.currentDirectory.children?.forEach((child) => {
      if (child.id === node.id) {
        result = true
      }
    })
    return result
  }

  pathCalculation() {
    if (this.currentDirectory != null && typeof this.currentDirectory.name != "undefined") {
      const result = [this.currentDirectory.name]
      let parentId = this.currentDirectory.parentId
      let parentDirectoryFound: boolean
      this.subscriptions.add(
        this.directoryService.getDirectories().subscribe((directories) => {
          do {
            parentDirectoryFound = false
            directories.forEach((directory) => {
              if (directory.id === parentId) {
                result.push(directory.name as string)
                parentId = directory.parentId
                parentDirectoryFound = true
              }
            })
          } while (parentDirectoryFound)
          result.push("Home")
          if (result[0] === "") {
            this.directoryPath = result[1]
          } else {
            this.directoryPath = result.reverse().join(" > ")
          }
        })
      )
    }
  }

  onNodeClick(node: Directory) {
    this.directoryComponent.onNodeClick(node)
  }

  onDirectoryId(selectedDirectory: Directory | null) {
    if (selectedDirectory !== null) {
      this.currentDirectory = selectedDirectory
    }
    this.pathCalculation()
    this.resetPagination()
    this.handlePage()
  }

  onMediaDropped(event: string) {
    this.handlePage()
  }

  handleDragStart(event: CdkDragStart): void {
    this.dragging = true
  }

  handleDragEnded(event: CdkDragStart): void {
    this.dragging = false
  }

  isMediaIncludedInCurrentScenario(mediaId: string): boolean {
    return this._mediaIdsIncludedInCurrentScenario.findIndex(includedInScenarioMediaId => includedInScenarioMediaId === mediaId) >= 0
  }

  onSortSelectionChange(sort: Sort): void {
    this.selectedSort = sort
    this.resetPagination()
    this.handlePage()
  }

  chooseInheritingTemplate(canCreateFullInherited: boolean) {
    this.subscriptions.add(
      this.matDialog.open(InheritableTemplateDialogComponent).afterClosed().pipe(
        filter(templateId => !!templateId),
        switchMap(templateId =>
          this.templateService.getTemplate(templateId).pipe(
            map(template => this.defineRouteParameters(template, canCreateFullInherited))
          )
        )
      ).subscribe(route => {
        this.router.navigate(route);
      })
    );
  }

  defineRouteParameters(template: Template, canCreateFullInherited: boolean): string[] {
    switch (template.type) {
      case TemplateType.HUMECANVA:
        if (canCreateFullInherited) {
          return ["screenlab", "templates", "humecanva", template.id as string, "full-instances", "create"]
        }
        return ["screenlab", "templates", "humecanva", template.id as string, "instances", "create"]
      case TemplateType.CLASSIC:
        if (canCreateFullInherited) {
          return ["screenlab", "templates", template.id as string, "full-instances", "create"]
        }
        return ["screenlab", "templates", template.id as string, "instances", "create"]
    }

    return []
  }

  toggleDropdown() {
    this.showFormMedia = !this.showFormMedia
  }

  handleSearchKeyUp(event: KeyboardEvent) {
    this.searchKeyUp.next((event.target as HTMLInputElement).value)
  }

  castToMedia(data: any): Media {
    return data as Media
  }

  clearValidityDates() {
    this.mediaFilterForm.patchValue({
      validityStart: undefined,
      validityEnd: undefined,
    })
    this.resetPagination()
    this.handlePage()
  }

  formatDate() {
    if (this.mediaFilterForm.get("validityEnd")?.value && this.mediaFilterForm.get("validityEnd")?.value !== undefined) {
      let endDate = this.mediaFilterForm.get("validityEnd")?.value;
      endDate.setHours(23, 59, 59, 999)
      this.mediaFilterForm.patchValue({
        'validityEnd': endDate
      })
    }
    this.onFilterSelectionChanged()
  }
}
